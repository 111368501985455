import { getField} from "vuex-map-fields";
import mutations from "./mutations";
import * as actions from './actions';

export default {
    namespaced: true,
    state: {
        isLoading: false,
        error: '',
        order: [],
        status: false
    },
    actions,
    getters: {
        getField
    },
    mutations: {
        ...mutations
    }
}
