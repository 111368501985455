import Vue from 'vue'
import App from './App.vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate/dist/vee-validate.full.esm';
import { isCreditCard } from 'validator';
import 'vue-select/dist/vue-select.css'
import 'document-register-element/build/document-register-element'
import StarRating from 'vue-star-rating'

import router from './router'
import store from './store'
import {i18n} from "./i18n-setup";
import vSelect from 'vue-select'
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter)
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);

import vueCustomElement from 'vue-custom-element'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

extend('credit_card', {
    validate: (val) => isCreditCard(val),
    message: 'Please enter a valid credit card'
});

import Toasted from 'vue-toasted';

Vue.use(Toasted)
Vue.toasted.register('co2_error', payload => {
    if(! payload.message) {
        return "Oops.. Something Went Wrong.."
    }

    return "Oops.. " + payload.message;
    },
    {
        type: "error",
        position: "top-center",
        duration : 5000
})

Vue.toasted.register('co2_success',
    payload => {
        if(! payload.message) {
            return "Success"
        }
        return payload.message;
    },
    {
        type: "success",
        position: "top-center",
        duration : 3000
    }
)

Vue.use(vueCustomElement)
Vue.filter('number', numFormat(numeral,2));
Vue.component('v-select', vSelect)
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('StarRating', StarRating);

Vue.config.productionTip = false


App.store = store
App.router = router
App.i18n= i18n
Vue.customElement('co2nsensus-widget', App)

