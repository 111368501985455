import { getField, updateField } from 'vuex-map-fields';
import * as actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    error: '',
    isLoading: false,
    items: [],
    selectItems: null,
    view: [],
    violations: null,
    selected: null,
    selectedItem: JSON.parse(localStorage.getItem('co2nsensus-selected-project')) || null,
  },
  actions,
  getters: {
    getField,
  },
  mutations: {
    updateField,
    ...mutations,
  }
};
