import { getField, updateField } from "vuex-map-fields";
import * as actions from './actions';
import mutations from "./mutations";
import * as _ from 'lodash'


export default {
    namespaced: true,
    state: {
        isLoading: false,
        error: '',
        created: null,
        violations: null,
        localItems: JSON.parse(window.localStorage.getItem('co2nsensus-widget-cart') || '[]'),
        isCompletedCart: false,
        deleted: null,
    },
    actions,
    getters: {
        getField,
        totalAmount (state) {
            return _.sumBy(state.localItems, (p) => {
                return p.amount
            })
        }
    },
    mutations: {
        updateField,
        ...mutations
    },
};
