import * as types from './mutation_types';

export default {
    [types.THANK_YOU_ERROR](state, error) {
        Object.assign(state, {error})
    },
    [types.THANK_YOU_LOADING_TOGGLE](state){
        Object.assign(state, {error: '', isLoading: !state.isLoading})
    },
    [types.THANK_YOU_ORDER](state, order) {
        Object.assign(state, {order});
    },
    [types.THANK_YOU_STATUS](state, status) {
        Object.assign(state, {status})
    }
}
