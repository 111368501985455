export const Currency = 'GBP' | 'USD' | 'AUD' | 'EUR' | 'CAD';

export const currencyOptions = [
    { label: 'GBP', value: 'GBP' },
    { label: 'USD', value: 'USD' },
    { label: 'EUR', value: 'EUR' },
    { label: 'AUD', value: 'AUD' },
    { label: 'CAD', value: 'CAD' },
    { label: 'TRY', value: 'TRY' },
];
export const currencySymbols = {
    GBP: '£',
    USD: '$',
    EUR: '€',
    AUD: 'A$',
    CAD: 'CA$',
    TRY: '₺'
};
export const currencyCodes = ['GBP', 'USD', 'AUD', 'EUR', 'CAD', 'TRY'];
export const currencyCodeLocationMapper = {
    AUD: 'AU',
    GBP: 'GB',
    EUR: 'EU',
    USD: 'US',
    CAD: 'CA',
    TRY: 'TR'
};
export const locationCurrencyCodeMapper = {
    AU: 'AUD',
    GB: 'GBP',
    EU: 'EUR',
    US: 'USD',
    CA: 'CAD',
    SG: 'USD',
    CN: 'USD',
    JP: 'USD',
    TR: 'TRY'
};
