<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading"
                 :can-cancel="true"
                 :is-full-page="true">
        </loading>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: "Co2Loading",
        data() {
            return {
                isLoading: true
            }
        },
        components: {Loading}
    }
</script>

<style scoped>

</style>
