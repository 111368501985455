<template>
    <div>
        <section class="co2nsensusCalculationWrapper">
            <div class="co2CalculationBody">
                <div class="co2CalculationContent">
                    <div class="co2TabWrapper">
                        <div class="co2TabNavigationWrapper">
                            <div class="co2TransportNav"
                                 title="Car Offset"
                                 @click="selectComponent('CarTransport')"
                                 :class="active === 'car' ? 'active': '' ">
                                <span>{{$t('module__transport__car')}}</span>
                            </div>
                            <div class="co2TransportNav"
                                 title="Mototbike Offset"
                                 @click="selectComponent('MotorBikeTransport')"
                                 :class="active === 'motorbike' ? 'active' : '' ">
                                <span>{{$t('module__transport__motorbike')}}</span>
                            </div>
                            <div class="co2TransportNav"
                                 title="Public Transport Offset"
                                 @click="selectComponent('PublicTransport')"
                                 :class="active === 'public' ? 'active' : '' ">
                                <span>{{$t('module__transport__public transport')}}</span>
                            </div>
                        </div>
                    </div>
                    <component :is="component"></component>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import CarTransport from "./Transport/CarTransport";
    import MotorBikeTransport from "./Transport/MotorBikeTransport";
    import PublicTransport from "./Transport/PublicTransport";
    export default {
        name: "TransporterLayout",
        components: {CarTransport, MotorBikeTransport, PublicTransport},
        data() {
            return {
                component: CarTransport,
                title: this.$t('car__title'),
                active: 'car',
            }
        },
        methods: {
            selectComponent(value) {
                switch (value) {
                    case 'CarTransport':
                        this.title = this.$t('car__title');
                        this.active = 'car';
                        break;
                    case 'MotorBikeTransport':
                        this.title = this.$t('motorBike__title');
                        this.active = 'motorbike';
                        break;
                    case 'PublicTransport':
                        this.title = this.$t('publicTransport__title');
                        this.active = 'public';
                }
                this.component = value;
            }
        }
    }
</script>

<style scoped>

</style>
