export const RESET = 'SALABLE_PROJECT_LIST_RESET';
export const SET_ITEMS = 'SALABLE_PROJECT_LIST_SET_ITEMS';
export const SET_ERROR = 'SALABLE_PROJECT_LIST_SET_ERROR';
export const SET_SELECT_ITEMS = 'SALABLE_PROJECT_LIST_SET_SELECT_ITEMS';
export const SET_VIEW = 'SALABLE_PROJECT_LIST_SET_VIEW';
export const TOGGLE_LOADING = 'SALABLE_PROJECT_LIST_TOGGLE_LOADING';
export const SET_SELECTED_PROJECT= 'SALABLE_PROJECT_SET_SELECT_PROJECT';
export const SELECTED_PROJECT = 'SALABLE_PROJECT_SELECTED_PROJECT';
export const SET_SELECTED_PRICE = 'SALABLE_PROJECT_SELECTED_SET_PRICE';
export const RESET_SELECTED_PROJECT = 'SALABLE_PROJECT_RESET';

