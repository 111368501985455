<template>
    <div>
        <Co2Loading v-if="isLoading"/>
        <section class="co2nsensusCalculationWrapper">
            <div class="co2CalculationBody">
                <div class="co2CalculationContent">
                    <h2 class="co2CalculationTitle">{{ $t('Plane__Flight') }}</h2>
                    <div class="co2CalculationStart">
                        <div class="co2nsensusCalculationDescription">{{ $t('Plane__Calculate the emissions of your flight.') }}</div>
                        <ValidationObserver v-slot="{ invalid }">
                            <form @submit.prevent="onSubmit">
                                <div class="co2Grid-1">
                                    <div class="co2FormGroup">
                                        <ValidationProvider name="departure" rules="required" v-slot="{ errors }">
                                            <v-select
                                                    :options="options"
                                                    :filterable="false"
                                                    :placeholder="$t('Plane__Departure')"
                                                    v-model="departure"
                                                    @search="onSearch">
                                                <template slot="no-options">
                                                    {{$t('Plane__Departure')}}  {{ $t('Plane__City Or Airport') }}
                                                </template>
                                                <template slot="option" slot-scope="option">
                                                    <strong>{{ option.code}}</strong> - {{ option.name }} - {{ option.ctryName }}
                                                </template>
                                                <template slot="selected-option" slot-scope="option">
                                                    <div class="selected d-center">
                                                        <i class="co2nsensus-iconucus-kalkis-icon"></i>
                                                        <strong>{{ option.code}}</strong> - {{ option.name }} <span class="countryName">- {{ option.ctryName }}</span>
                                                    </div>
                                                </template>
                                            </v-select>
                                            <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="co2Grid-1">
                                    <div class="co2FormGroup">
                                        <ValidationProvider name="destination" rules="required|is_not:@departure" v-slot="{ errors }">
                                            <v-select
                                                    :options="options"
                                                    :filterable="false"
                                                    v-model="destination"
                                                    :placeholder="$t('Plane__Destination')"
                                                    @search="onSearch">
                                                <template slot="no-options">
                                                    {{$t('Plane__Destination')}} {{ $t('Plane__City Or Airport') }}
                                                </template>
                                                <template slot="option" slot-scope="option">
                                                    <strong>{{ option.code}}</strong> - {{ option.name }} - {{ option.ctryName }}
                                                </template>
                                                <template slot="selected-option" slot-scope="option">
                                                    <div class="selected d-center">
                                                        <i class="co2icon-landing"></i>
                                                        <strong>{{ option.code}}</strong> - {{ option.name }} <span class="countryName">- {{ option.ctryName }}</span>
                                                    </div>
                                                </template>
                                            </v-select>
                                            <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="flightCalculatorTreeColumn">
                                    <div class="co2FormGroup first">
                                        <h4>{{$t('Plane__Passengers')}}</h4>
                                        <ValidationProvider
                                                name="passenger"
                                                rules="required|numeric|is_not:0"
                                                v-slot="{ errors, classes }">
                                            <input type="number" v-model="passenger" class="co2FormControl" :class="classes" :placeholder="$t('Plane__Passengers')">
                                            <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="co2FormGroup">
                                        <div class="radiosArea">
                                            <h4>{{$t('Plane__Trip')}}</h4>
                                            <div class="radiosContent">
                                                <div class="radios">
                                                    <label>
                                                        <input type="radio" id="return" value="2"  v-model="direction" checked>
                                                        <span class="icon">
                                                            <i class="co2nsensus-iconround-trip-flight-grey"></i>
                                                            <span for="return" title="Round trip">{{ $t('Plane__Round trip') }}</span>
                                                        </span>
                                                    </label>
                                                </div>
                                                <div class="radios">
                                                    <label>
                                                        <input type="radio" id="oneway" value="1" v-model="direction">
                                                        <span class="icon">
                                                            <i class="co2nsensus-iconone-way-flight-grey"></i>
                                                            <span for="oneway" title="One way">{{ $t('Plane__One way') }}</span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="co2FormGroup">
                                        <div class="radiosArea">
                                            <h4>{{ $t('Plane__Cabin Class')}}</h4>
                                            <div class="radiosContent">
                                                <div class="radios">
                                                    <label>
                                                        <input type="radio" id="economy" value="economy" v-model="cabinClass" checked>
                                                        <span class="icon">
                                                            <i class="co2nsensus-iconeconomy-class-grey"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                                                            <span for="economy" title="Economy">{{ $t('Plane__Economy')}}</span>
                                                        </span>
                                                    </label>
                                                </div>
                                                <div class="radios">
                                                    <label>
                                                        <input type="radio" id="business" value="business" v-model="cabinClass">
                                                        <span class="icon">
                                                            <i class="co2nsensus-iconbusiness-class-grey"><span class="path1"></span><span class="path2"></span> </i>
                                                            <span for="business" title="Business">{{ $t('Plane__Business') }}</span>
                                                        </span>
                                                    </label>
                                                </div>
                                                <div class="radios">
                                                    <label>
                                                        <input type="radio" id="first" value="first" v-model="cabinClass">
                                                        <span class="icon">
                                                            <i class="co2nsensus-iconfirst-class-grey"><span class="path1"></span><span class="path2"></span></i>
                                                            <span for="first" title="First Class">{{ $t('Plane__First') }}</span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="co2Grid-1">
                                    <button type="submit" class="co2Button buttonCTA" :disabled="invalid">{{ $t('common::Buttons__Calculate') }}</button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import Co2Loading from "./Co2Loading";
    import { mapFields} from 'vuex-map-fields';
    import {ENTRYPOINT} from "../config/entrypoint";
    import * as _ from 'lodash';
    import {SUGGEST_PORT} from "../constant/suggest_port";

    export default {
        name: "FlightOffset",
        components: {Co2Loading},
        data() {
            return {
                departure: '',
                destination: '',
                passenger: 1,
                cabinClass: 'economy',
                direction: 2,
                options: SUGGEST_PORT || []
            }
        },
        computed: {
            ...mapFields('cart',[
                'error',
                'isLoading',
                'created',
                'violations',
                'isCompletedCart'
            ])
        },
        methods: {
            ...mapActions('cart',['addToCart', 'cartReset']),
            ...mapActions('projects', ['projectReset']),
            onSearch(search, loading) {
                loading(true);
                this.search(loading, search, this);
            },
            search: _.debounce((loading, search, vm) => {

                if (search.length >=2 ) {
                    fetch(
                        `${ENTRYPOINT}/ports?code[]=${escape(search)}&name[]=${escape(search)}&pagination=true`
                    ).then((res) => {
                        res.json().then((json) => (vm.options = json['hydra:member']));
                        loading(false);
                    });
                } else {
                    loading(false)
                }

            }, 350),
            onSubmit () {
                let endpoint = 'calculator/flight';
                let payload = {
                    departure: this.departure['code'],
                    destination: this.destination['code'],
                    passenger: parseInt(this.passenger),
                    cabinClass: this.cabinClass,
                    direction: parseInt(this.direction),
                    cart: true
                };

                this.addToCart({endpoint, payload});
            },
            reset () {
                this.cartReset();
                this.departure = '';
                this.destination = '';
            },

        },
        watch: {
            created: function (created) {
                if (!created) {
                    return;
                }
                this.projectReset();
                this.$toasted.global.co2_success({
                    message: this.$t('Message__CartAdd', {quantity: created.data.emission })
                })
                this.$router.push({name: 'Cart'});
            },
            error: function (error) {
                if (!error) return;
                this.$toasted.global.co2_error({
                    message: error
                });

            }
        },
        mounted() {

            this.options.map(function (x){
                return x.item_data = x.code + ' - ' + x.name
            });

        },
    };
</script>

<style lang="less">
    @import "../assets/css/vars";
    @import "../assets/css/form";
    @import "../assets/css/grid";
    @import "../assets/css/button";


    .co2FlightGrid2 {
        display: grid;
        grid-template-columns: 1fr 1fr !important;
        grid-gap: 20px !important;
        align-items: center !important;
        @media @phone {
            grid-template-columns: 1fr !important;
            grid-gap: 5px !important;
        }
    }

    .flightMultiItem {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
    }

/*
    .vs__selected {
        white-space: nowrap;
        width: 100%;
        max-width: 260px;
        overflow: hidden;
        text-overflow: "...";
    }


 */

    .d-center {
        display: flex !important;
        align-items: center !important;
    }

    .selected {
        font-size: .95em !important;
        font-weight: 600 !important;
        & i {
            font-size: 30px !important;
            color: rgb(131, 141, 147) !important;
            margin-right: 0.5rem !important;
            @media @mobile {
                display: none !important;
            }
        }
        & .countryName {
            @media @mobile {
                display: none !important;
            }
        }
    }

    .v-select .dropdown li {
        border-bottom: 1px solid rgba(112, 128, 144, 0.1) !important;
    }

    .v-select .dropdown li:last-child {
        border-bottom: none !important;
    }

    .v-select .dropdown li a {
        padding: 10px 20px !important;
        width: 100% !important;
        font-size: 1.25em !important;
        color: #3c3c3c !important;
    }

    .v-select .dropdown-menu .active > a {
        color: #fff !important;
    }
</style>
