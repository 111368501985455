var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('Co2Loading'):_vm._e(),_c('section',{staticClass:"co2nsensusCalculationWrapper"},[_c('div',{staticClass:"co2CalculationBody"},[_c('div',{staticClass:"co2CalculationContent"},[_c('h2',{staticClass:"co2CalculationTitle"},[_vm._v(_vm._s(_vm.$t('building__title')))]),_c('div',{staticClass:"co2CalculationStart"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"co2Grid-2 co2Gap20"},[_c('div',{staticClass:"co2FormGroup"},[_c('h4',[_vm._v(_vm._s(_vm.$t('building__Time Period Start')))]),_c('ValidationProvider',[_c('DatePicker',{attrs:{"valueType":"format"},model:{value:(_vm.timePeriodStart),callback:function ($$v) {_vm.timePeriodStart=$$v},expression:"timePeriodStart"}})],1)],1),_c('div',{staticClass:"co2FormGroup"},[_c('h4',[_vm._v(_vm._s(_vm.$t('building__Time Period Finish')))]),_c('ValidationProvider',[_c('DatePicker',{attrs:{"valueType":"format"},model:{value:(_vm.timePeriodFinish),callback:function ($$v) {_vm.timePeriodFinish=$$v},expression:"timePeriodFinish"}})],1)],1)]),_c('div',{staticClass:"co2Grid-2 co2Gap20"},[_c('div',{staticClass:"co2FormGroup"},[_c('h4',[_vm._v(_vm._s(_vm.$t('building__Country')))]),_c('ValidationProvider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countries},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"co2FormGroup"},[_c('h4',[_vm._v(_vm._s(_vm.$t('building__Total electricity consumption (kWh)')))]),_c('ValidationProvider',{attrs:{"name":"electricity","rules":"required|integer|is_not:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.electricity),expression:"electricity"}],staticClass:"co2FormControl",class:classes,domProps:{"value":(_vm.electricity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.electricity=$event.target.value}}}),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"co2Grid-2 co2Gap20"},[_c('div',{staticClass:"co2FormGroup"},[_c('h4',[_vm._v(_vm._s(_vm.$t('building__Total natural gas consumption')))]),_c('div',{staticClass:"co2FormInputGroups"},[_c('ValidationProvider',{attrs:{"name":"gas","rules":"integer|is_not:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gas),expression:"gas"}],staticClass:"co2FormControl ilk",class:classes,attrs:{"type":"number"},domProps:{"value":(_vm.gas)},on:{"input":function($event){if($event.target.composing){ return; }_vm.gas=$event.target.value}}}),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.natural_gas_unit),expression:"natural_gas_unit"}],staticClass:"co2FormControl ikinci co2selectBoxControl",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.natural_gas_unit=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.natural_gas_units),function(unit){return _c('option',{key:unit.label,domProps:{"value":unit.label}},[_vm._v(_vm._s(unit.name()))])}),0)])],1)]),_c('div',{staticClass:"co2FormGroup"},[_c('h4',[_vm._v(_vm._s(_vm.$t('building__Total lpg consumption')))]),_c('div',{staticClass:"co2FormInputGroups"},[_c('ValidationProvider',{attrs:{"name":"lpg","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lpg),expression:"lpg"}],staticClass:"co2FormControl ilk",class:classes,attrs:{"type":"number"},domProps:{"value":(_vm.lpg)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lpg=$event.target.value}}}),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.lpg_unit),expression:"lpg_unit"}],staticClass:"co2FormControl ikinci co2selectBoxControl",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.lpg_unit=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.lpg_units),function(unit){return _c('option',{key:unit.label,domProps:{"value":unit.label}},[_vm._v(_vm._s(unit.name()))])}),0)])],1)])]),_c('div',{staticClass:"co2Grid-2 co2Gap20"},[_c('div',{staticClass:"co2FormGroup"},[_c('h4',[_vm._v(_vm._s(_vm.$t('building__Total heating oil consumption')))]),_c('div',{staticClass:"co2FormInputGroups"},[_c('ValidationProvider',{attrs:{"name":"heating_oil","rules":"integer|is_not:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.heating_oil),expression:"heating_oil"}],staticClass:"co2FormControl ilk",class:classes,attrs:{"type":"number"},domProps:{"value":(_vm.heating_oil)},on:{"input":function($event){if($event.target.composing){ return; }_vm.heating_oil=$event.target.value}}}),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.heating_oil_unit),expression:"heating_oil_unit"}],staticClass:"co2FormControl ikinci co2selectBoxControl",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.heating_oil_unit=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.heating_oil_units),function(unit){return _c('option',{key:unit.label,domProps:{"value":unit.label}},[_vm._v(_vm._s(unit.name()))])}),0)])],1)]),_c('div',{staticClass:"co2FormGroup"},[_c('h4',[_vm._v(_vm._s(_vm.$t('building__Total coal consumption')))]),_c('div',{staticClass:"co2FormInputGroups"},[_c('ValidationProvider',{attrs:{"name":"coal","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.coal),expression:"coal"}],staticClass:"co2FormControl ilk",class:classes,attrs:{"type":"number"},domProps:{"value":(_vm.coal)},on:{"input":function($event){if($event.target.composing){ return; }_vm.coal=$event.target.value}}}),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.coal_unit),expression:"coal_unit"}],staticClass:"co2FormControl ikinci co2selectBoxControl",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.coal_unit=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.coal_units),function(unit){return _c('option',{key:unit.label,domProps:{"value":unit.label}},[_vm._v(_vm._s(unit.name()))])}),0)])],1)])]),_c('div',{staticClass:"co2Grid-1"},[_c('button',{staticClass:"co2Button buttonCTA",attrs:{"type":"submit","disabled":invalid}},[_vm._v("Calculate")])])])]}}])})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }