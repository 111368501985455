import {ENTRYPOINT} from "../../../config/entrypoint";
import axios from 'axios';
import * as types from './mutation_types';

const entryPoint = ENTRYPOINT + (ENTRYPOINT.endsWith('/') ? '' : '/');


export const getItems = async ( {commit}, payload) => {
    commit(types.TOGGLE_LOADING);
    return new Promise((resolve, reject) => {
        axios({
            url: entryPoint+'project_prices?locale='+localStorage.getItem('co2-widget-language-code'),
            data: payload,
            method: 'POST' })
            .then((response) => {
                commit(types.TOGGLE_LOADING);
                commit(types.SET_ITEMS, response.data.projects);
                //commit(types.SET_VIEW, response.data['hydra:view']);
                resolve(response);
            })
            .catch( (e) => {
                commit(types.TOGGLE_LOADING);
                commit(types.SET_ERROR, e.message);
                reject(e)
            })
    })
};

export const selectProject = ( {commit}, project) => {
    commit(types.TOGGLE_LOADING);
    return [
        commit(types.SET_SELECTED_PROJECT, project),
        commit(types.SELECTED_PROJECT, project),
        localStorage.setItem('co2nsensus-selected-price', project.price),
        commit(types.TOGGLE_LOADING)
    ]
}

export const projectReset = ({commit}) => {
    commit(types.RESET);
};

export const cartItemDeleted = ({ commit }) => {
    commit(types.RESET_SELECTED_PROJECT);
}
