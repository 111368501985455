<template>
    <div>
        <div class="co2NavigationWrapper">
            <router-link :to="{name: 'Home'}" class="co2NavItem" :title="`${$t('common::Navigation__Flight')} Calculator`" exact>
                <i class="co2nsensus-iconflight"></i>
                <span>{{$t('common::Navigation__Flight')}}</span>
            </router-link>
            <router-link :to="{name: 'Transporter'}" class="co2NavItem"  :title="`${$t('common::Navigation__Transport')} Calculator`">
                <i class="co2nsensus-iconcar"></i>
                <span>{{$t('common::Navigation__Transport')}}</span>
            </router-link>
            <router-link :to="{name: 'Accommodation'}" class="co2NavItem" :title="`${$t('common::Navigation__Accommodation')}`">
              <i class="co2nsensus-iconaccomodation accommodation"></i>
              <span>{{ $t('common::Navigation__Accommodation') }}</span>
            </router-link>
            <router-link :to="{name: 'Shipping'}" class="co2NavItem" :title="`${$t('common::Navigation__Shipping')} Calculator`">
                <i class="co2nsensus-iconshipping"></i>
                <span>{{$t('common::Navigation__Shipping')}}</span>
            </router-link>
            <router-link :to="{name: 'Event'}" class="co2NavItem" :title="`${$t('common::Navigation__Event')} Calculator`">
                <i class="co2nsensus-iconevent"></i>
                <span>{{$t('common::Navigation__Event')}}</span>
            </router-link>
            <router-link :to="{name: 'Building'}" class="co2NavItem" :title="`${$t('common::Navigation__Building')} Calculator`">
                <i class="co2nsensus-iconhouse"></i>
                <span>{{$t('common::Navigation__Building')}}</span>
            </router-link>
            <router-link :to="{name: 'Direct'}" class="co2NavItem" :title="`${$t('common::Navigation__Direct')} Offset`">
                <i class="co2nsensus-icondirect"></i>
                <span>{{$t('common::Navigation__Direct')}}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CalculationNavigation"
    }
</script>

<style>
    @import '../assets/css/navigation.css';
    @import '../assets/css/icon.css';
</style>
