<template>
    <div>
        <Co2Loading v-if="isLoading"></Co2Loading>
        <section class="co2nsensusCalculationWrapper">
            <div class="co2CalculationBody">
                <div class="co2CalculationContent" v-if="order.state === 'fulfilled' ">
                    <h2 class="co2CalculationTitle">{{ $t('Thanks__Thank you for saving the planet!')}}</h2>
                    <div class="co2CalculationStart">
                        <div class="co2Grid-1 co2TextCenter">
                            <h3>{{$t('Thanks__Payment successful')}}</h3>
                        </div>
                        <div class="co2TextCenter">
                            <a class="co2Button buttonCTA downLoad" target="_blank" :href="`${endpoint}/certificate/${order.id}/show-pdf-file`">{{$t('common::Buttons__Download certificate')}}</a>
                        </div>
                      <div class="co2TextCenter" v-html="$t('Thanks__Co2nsensus Account')"></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapFields } from "vuex-map-fields";
    import { mapActions } from "vuex";
    import Co2Loading from "./Co2Loading";
    import {ENTRYPOINT} from "@/config/entrypoint";

    export default {
        name: "ThankYou",
        components: {Co2Loading},
        data() {
          return {
              endpoint: ENTRYPOINT
          }
        },
        computed: {
            ...mapFields('checkout_status', [
                'isLoading',
                'error',
                'order',
                'status'
            ]),
        },
        methods: {
            ...mapActions('checkout_status', [
                'getOrder'
            ]),
            ...mapActions('cart', [
                'clearCart'
            ]),
            ...mapActions('checkout', [
                'clearOrder'
            ]),
            ...mapActions('projects', [
                'projectReset'
            ]),

            reset() {
                this.clearCart();
                this.clearOrder();
                this.projectReset();
            }
        },
        created() {
            this.getOrder(this.$route.params.id);
            this.reset();
        },
        watch: {
            status: function (status) {
                if (!status) return;
                this.reset();
            }
        }
    }
</script>

<style scoped>

</style>
