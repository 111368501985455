import Vue from 'vue'
import Vuex from 'vuex'
import new_widget from "./modules/widget"
import makeCrudModule from "./modules/crud";
import notifications from "./modules/notifications";
import distanceService from "../services/distance";
import cart from './modules/cart';
import projects from './modules/projects';
import checkout from './modules/order';
import checkout_status from './modules/order/thanks';
import stripe_token from './modules/stripe/token';
import charge from './modules/stripe/charge';
import paypal from './modules/paypal/create';
import currency from "./modules/curency";
import zone from './modules/country/ipapi';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const localStoragePlugin = store => {
  store.subscribe((mutation, { cart }) => {
    localStorage.setItem('co2nsensus-widget-cart', JSON.stringify(cart.localItems))
  })
}
const plugins = [
    localStoragePlugin,
    createPersistedState()
  ];

const store = new Vuex.Store({
  plugins,
  modules: {
    notifications,
    new_widget,
    distance: makeCrudModule({
      service: distanceService
    }),
    cart,
    projects,
    checkout,
    stripe_token,
    charge,
    paypal,
    checkout_status,
    currency,
    zone
  },
  strict: process.env.NODE_ENV !== 'production',
});

export default store;
