export const SUGGEST_PORT = [
    {
        "id": 4598,
        "code": "LHR",
        "name": "London Heathrow Apt",
        "ctryName": "United Kingdom"
    },
    {
        "id": 4942,
        "code": "MAN",
        "name": "Manchester",
        "ctryName": "United Kingdom"
    },
    {
        "id": 8464,
        "code": "SYD",
        "name": "Sydney Kingsford Smith Apt",
        "ctryName": "Australia"
    },
    {
        "id": 6228,
        "code": "OSL",
        "name": "Oslo Gardermoen Airport",
        "ctryName": "Norway"
    },
    {
        "id": 5047,
        "code": "MEL",
        "name": "Melbourne Airport",
        "ctryName": "Australia"
    },
    {
        "id": 3727,
        "code": "JFK",
        "name": "New York J F Kennedy International Apt",
        "ctryName": "USA"
    },
    {
        "id": 2994,
        "code": "GVA",
        "name": "Geneva",
        "ctryName": "Switzerland"
    },
    {
        "id": 2812,
        "code": "GLA",
        "name": "Glasgow International Airport",
        "ctryName": "United Kingdom"
    },
    {
        "id": 2170,
        "code": "EDI",
        "name": "Edinburgh",
        "ctryName": "United Kingdom"
    },
    {
        "id": 2078,
        "code": "DUB",
        "name": "Dublin",
        "ctryName": "Ireland Republic of"
    },
    {
        "id": 1591,
        "code": "CPH",
        "name": "Copenhagen Kastrup Apt",
        "ctryName": "Denmark"
    },
    {
        "id": 1301,
        "code": "CDG",
        "name": "Paris Charles de Gaulle Apt",
        "ctryName": "France"
    }
]
