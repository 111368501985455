<template>
    <div>
        <FlightOffset/>
    </div>
</template>

<script>
    import FlightOffset from "./FlightOffset";
    export default {
        name: "Home",
        components: {FlightOffset}
    }
</script>

<style scoped>

</style>
