import * as types from './mutation_types';

export default {
    [types.SELECT_CURRENCY_ERROR](state, error) {
        Object.assign(state, {error})
    },

    [types.SELECT_CURRENCY_LOADING](state) {
        Object.assign(state, {error: '', isLoading: !state.isLoading})
    },

    [types.SELECT_CURRENCY](state, currency) {
        localStorage.setItem('co2Currency', currency);
        Object.assign(state, { currency: currency})
    }
};
