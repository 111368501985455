<template>
  <div>
    <section class="co2nsensusCalculationWrapper">
      <div class="co2CalculationBody">
        <div class="co2CalculationContent">
          <div class="co2TabWrapper">
            <div class="co2TabNavigationWrapper">
              <div class="co2TransportNav"
                   @click="selectComponent('Hotel')"
                   :class="active === 'Hotel' ? 'active': '' ">
                <span>{{$t('Accommodation__Hotel')}}</span>
              </div>
              <div class="co2TransportNav"
                   @click="selectComponent('Resort')"
                   :class="active === 'Resort' ? 'active' : '' ">
                <span>{{$t('Accommodation__Holiday Resort')}}</span>
              </div>
              <div class="co2TransportNav"
                   @click="selectComponent('Apart')"
                   :class="active === 'Apart' ? 'active' : '' ">
                <span>{{$t('Accommodation__Rentals')}}</span>
              </div>
            </div>
          </div>
          <component :is="component"></component>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import Hotel from "@/components/Accommodation/Hotel";
  import Resort from "@/components/Accommodation/Resort";
  import Apart from "@/components/Accommodation/Apart";
  export default {
    name: "Accommodation",
    components: {Hotel, Resort, Apart},
    data(){
      return {
        component: Hotel,
        active: 'Hotel'
      }
    },
    methods: {
      selectComponent(value) {
        switch (value) {
          case 'Hotel':
            this.active = 'Hotel';
            break;
          case 'Resort':
            this.active = 'Resort';
            break;
          case 'Apart':
            this.active = 'Apart';
        }
        this.component = value;
      }
    }
  }
</script>

<style lang="less">
    @import "../assets/css/form";
</style>
