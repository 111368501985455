<template>
  <div id="co2CalculatorApp" :class="classes">
    <div class="co2Wrapper">
      <CalculationNavigation/>
      <cart-summary></cart-summary>
      <router-view/>
    </div>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import { mapFields} from "vuex-map-fields";
  import CalculationNavigation from "./components/CalculationNavigation";
  import CartSummary from "./components/CartSummary";

  export default {
    components: {CartSummary, CalculationNavigation},
    props: {
      widgetId: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: false
      },
      classes: {
        type: String,
        required: false,
        default: 'light'
      }
    },
    computed: {
      ...mapFields('new_widget', [
              'isLoading',
              'error',
              'widget',
              'baseCurrency'
      ])
    },
    methods: {
      ...mapActions('new_widget', [
              'getWidget'
      ]),
      ...mapActions('currency', [
              'changeCurrency'
      ]),
      ...mapActions('zone', ['getCountryCode']),
      checkLocalWidget() {
        this.getWidget(this.widgetId);
        if (!localStorage.getItem('Co2Widget')) {
          //this.getWidget(this.widgetId);
        }
      },
      checkLocalCountryCode() {
        if (!localStorage.getItem('co2CountryCode')) {
          this.getCountryCode();
        }
      }

    },
    async created() {
      this.checkLocalCountryCode();
      this.checkLocalWidget(this.widgetId);
    },
    watch: {
      baseCurrency: function (baseCurrency) {
        if (!baseCurrency) return;
        this.changeCurrency(baseCurrency);
      }
    }

  }
</script>

<style >
  @import "~normalize.css";
  @import "assets/css/default.css";
  ._vue-flash-msg-body {
    z-index: 999999 !important;
  }
</style>
