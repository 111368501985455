<template>
    <div>
        <Co2Loading v-if="isLoading"/>
        <section class="co2nsensusCalculationWrapper">
            <div class="co2CalculationBody">
                <div class="co2CalculationContent">
                    <h2 class="co2CalculationTitle">{{ $t('direct__title')}}</h2>
                    <div class="co2CalculationStart">
                        <ValidationObserver v-slot="{ invalid }">
                            <form @submit.prevent="onSubmit">
                                <div class="co2Grid-1 co2Gap20">
                                    <div class="co2FormGroup">
                                        <h4>{{ $t('direct__Carbon Emission (kg)')}}</h4>
                                        <ValidationProvider name="quantity" rules="required|integer|is_not:0" v-slot="{ errors, classes }">
                                            <input type="number" min="1000" class="co2FormControl" v-model="quantity" :class="classes">
                                            <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="co2Grid-1">
                                    <button type="submit" class="co2Button buttonCTA" :disabled="invalid">Offset Now</button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import Co2Loading from "./Co2Loading";
    import { mapFields} from 'vuex-map-fields';

    export default {
        name: "DirectOffset",
        components: {Co2Loading},
        data() {
            return {
                quantity: ''
            }
        },
        computed: {
            ...mapFields('cart',[
                'error',
                'isLoading',
                'created',
                'violations',
                'isCompletedCart'
            ])
        },
        methods: {
            ...mapActions('cart',['addToCart', 'cartReset']),
            ...mapActions('projects', ['projectReset']),
            onSubmit () {
                let endpoint = 'calculator/direct';
                let payload = {
                    quantity: parseInt(this.quantity),
                    cart: true
                };

                this.addToCart({endpoint, payload});
            },
        },
        watch: {
            created: function (created) {
                if (!created) {
                    return;
                }
                this.projectReset();
                this.$toasted.global.co2_success({
                    message: this.$t('Message__CartAdd', {quantity: created.data.emission })
                })
                this.$router.push({name: 'Cart'});
            },
            error: function (error) {
                if (!error) return;
                this.$toasted.global.co2_error({
                    message: error
                });

            }
        },
    }
</script>

<style scoped>

</style>
