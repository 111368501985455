<template>
    <div>
        <Co2Loading v-if="isLoading"></Co2Loading>
        <section class="co2nsensusCalculationWrapper">
            <div class="co2CalculationBody">
                <div class="co2CalculationContent">
                    <h2 class="co2CalculationTitle">{{ $t('common::Checkout__Checkout')}}</h2>
                    <div class="co2CalculationStart" v-if="selectedItem">
                        <ValidationObserver v-slot="{ invalid }">
                            <form @submit.prevent="onSubmit">
                                <div class="co2CheckOutWrapper">
                                    <div class="co2CheckOutWrapperContactInformation">
                                        <h4 class="co2Cizik">{{ $t('common::Checkout__Contact information')}}</h4>
                                        <div class="co2Grid-2 co2Gap20">
                                            <div class="co2FormGroup">
                                                <ValidationProvider rules="required" name="firstName" v-slot="{ errors, classes}">
                                                    <input type="text" class="co2FormControl" v-model="firstName" :class="classes" :placeholder="$t('common::Checkout__First name')">
                                                    <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="co2FormGroup">
                                                <ValidationProvider rules="required" name="lastName" v-slot="{ errors, classes}">
                                                    <input type="text" class="co2FormControl" v-model="lastName" :class="classes" :placeholder="$t('common::Checkout__Last name')">
                                                    <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div class="co2Grid-1 ">
                                            <div class="co2FormGroup">
                                                <ValidationProvider name="email" rules="required|email" v-slot="{ errors, classes }">
                                                    <input
                                                            type="email"
                                                            class="co2FormControl"
                                                            v-model="email"
                                                            :class="classes"
                                                            :placeholder="$t('common::Checkout__Email')">
                                                    <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div class="co2Grid-1">
                                            <div class="co2FormGroup">
                                                <ValidationProvider name="company">
                                                    <input type="text" v-model="company" class="co2FormControl" :placeholder="$t('common::Checkout__Company (optional)')">
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div class="co2Grid-2 co2Gap20" v-if="company">
                                            <div class="co2FormGroup">
                                                <ValidationProvider name="taxNumber" rules="max:50" v-slot="{ errors, classes}">
                                                    <input type="text" v-model="taxNumber" class="co2FormControl" :class="classes" :placeholder="$t('common::Checkout__Tax number')">
                                                    <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="co2FormGroup">
                                                <ValidationProvider name="country" rules="required" v-slot="{ errors }">
                                                    <v-select :options="countries"
                                                              :value="countries.label"
                                                              v-model="country"
                                                              :placeholder="$t('common::Checkout__Country')"
                                                    ></v-select>
                                                    <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div class="co2Grid-2 co2Gap20" v-if="company">
                                            <div class="co2FormGroup">
                                                <ValidationProvider name="city" rules="max:100" v-slot="{ errors, classes}">
                                                    <input type="text" v-model="city" class="co2FormControl" :class="classes" :placeholder="$t('common::Checkout__City')">
                                                    <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>

                                            <div class="co2FormGroup" v-if="company">
                                                <ValidationProvider name="zipCode" rules="max:10" v-slot="{ errors, classes }">
                                                    <input type="text"
                                                           v-model="zipCode"
                                                           class="co2FormControl"
                                                           :class="classes"
                                                           :placeholder="$t('common::Checkout__Postcode')">
                                                    <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div class="co2Grid-1" v-if="company">
                                            <div class="co2FormGroup">
                                                <ValidationProvider name="address" rules="required" v-slot="{ errors, classes }">
                                                    <input type="text" v-model="address" class="co2FormControl" :class="classes" :placeholder="$t('common::Checkout__Address')">
                                                    <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="co2CheckOutWrapperPaymentMethod">
                                        <h4 class="co2Cizik full">{{ $t('common::Checkout__Payment methods') }}</h4>
                                        <div class="mobilePaymentOptions">
                                            <div class="radios">
                                                <label>
                                                    <input type="radio" id="stripe" value="stripe" v-model="paymentMethod" checked>
                                                    <span class="icon">
                                                    <i class="co2nsensus-iconcard-payment-icon-grey"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></i>
                                                    <span for="stripe" title="Credit Card">{{ $t('common::Checkout__Credit card')}}</span>
                                                </span>
                                                </label>
                                            </div>
                                            <div class="radios">
                                                <label>
                                                    <input type="radio" id="paypal" value="paypal" v-model="paymentMethod" checked>
                                                    <span class="icon">
                                                    <i class="co2nsensus-iconpaypal-payment-grey"><span class="path1"></span><span class="path2"></span><span class="path3"></span> </i>
                                                    <span for="paypal" title="Paypal">Paypal</span>
                                                </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="co2Grid-1">
                                    <button type="submit" class="co2Button buttonCTA" :disabled="invalid">{{ $t('common::Buttons__Proceed to payment')}}</button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                    <div class="co2CalculationStart" v-else>
                        <div class="co2Grid-1 co2TextCenter" v-if="localItems <= 0 ">
                            <h4>{{$t('common::Checkout__Your Cart is currently empty.')}}</h4>
                        </div>
                        <div class="co2Grid-1 co2TextCenter" v-else>
                            <h4>{{$t('common::Checkout__Support a carbon offset project of your choice')}}</h4>
                            <router-link :to="{name: 'Cart' }">
                                <button class="co2Button buttonTextOnlyWithArrow">{{$t('common::Checkout__Select project')}}</button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { countryOptions} from "../constant/countries";
    import { mapGetters, mapActions} from 'vuex';
    import { mapFields} from 'vuex-map-fields';
    import Co2Loading from "./Co2Loading";
    export default {
        name: "Checkout",
        components: {Co2Loading},
        data() {
            return {
                email: (this.$store.state.checkout.orderInfo) ? this.$store.state.checkout.orderInfo.email : '',
                firstName: (this.$store.state.checkout.orderInfo) ? this.$store.state.checkout.orderInfo.firstName : '',
                lastName: (this.$store.state.checkout.orderInfo) ? this.$store.state.checkout.orderInfo.lastName : '',
                company: (this.$store.state.checkout.orderInfo) ? this.$store.state.checkout.orderInfo.company : '',
                address: (this.$store.state.checkout.orderInfo) ? this.$store.state.checkout.orderInfo.address : '',
                countries: countryOptions,
                country: (this.$store.state.checkout.orderInfo) ?
                    this.selectedCityId(countryOptions, this.$store.state.checkout.orderInfo.locale) :
                    this.selectedCityId(countryOptions, this.$store.state.zone.countryCode),
                zipCode: (this.$store.state.checkout.orderInfo) ? this.$store.state.checkout.orderInfo.zipCode : '',
                paymentMethod: (this.$store.state.checkout.orderInfo) ? this.$store.state.checkout.orderInfo.paymentMethod : 'stripe',
                taxNumber: '',
                city: ''
            }
        },
        computed: {
            ...mapFields('cart', [
                'localItems'
            ]),
            ...mapFields('projects', [
                'selectedItem'
            ]),
            ...mapFields('checkout', [
                'isLoading',
                'created',
                'orderInfo',
                'error'
            ]),
            ...mapFields('zone', ['countryCode']),
            ...mapGetters('cart',['totalAmount']),
            ...mapGetters('projects',['selectedProjectPrice']),
        },
        methods: {
            ...mapActions('checkout', ['createOrder', 'updateOrder']),
            onSubmit () {

                const cartItems = this.$store.state.cart.localItems.map( x => x.id);

                const payload = {
                    email: this.email,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    companyName: this.company,
                    address: this.address,
                    locale: this.country['value'],
                    zipCode: this.zipCode,
                    paymentMethod: this.paymentMethod,
                    amount: this.$store.getters["cart/totalAmount"],
                    total: this.$store.state.projects.selectedItem.price,
                    cartItems: cartItems,
                    projectId: this.$store.state.projects.selectedItem.projectId,
                    currency: this.$store.state.currency.currency,
                    promotionCouponId: null,
                    customerId: null,
                    rewardUsage: null,
                    referrerId: null,
                    utmSource: 'Widget'
                };

                if ( this.$store.state.checkout.orderInfo ) {
                    this.updateOrder(payload);
                } else {
                    this.createOrder(payload);
                }

            },

            selectedCityId(data, id) {
                this.country = data.filter( function (item) {
                    return item.value == id
                } );

                return this.country;
            }
        },
        watch: {
            created: function (created) {
                if (!created) {
                    return;
                }
                this.$router.push({name: `${this.paymentMethod.charAt(0).toUpperCase() + this.paymentMethod.slice(1)}` })
            },
            error: function (error) {
                if (!error) return;
                this.$toasted.global.co2_error({
                    message: error
                });
            }
        }
    }
</script>

<style lang="less">
    @import "../assets/css/form";
</style>
