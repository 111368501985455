import { getField} from "vuex-map-fields";
import * as actions from './actions';
import mutations from "./mutations";


export default {
    namespaced: true,
    state: {
        countryCode: localStorage.getItem('co2CountryCode') || ''
    },
    actions,
    getters: {
        getField
    },
    mutations: {
        ...mutations
    }
}
