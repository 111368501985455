import * as types from './mutation_types';

export default {
    [types.WIDGET_ERROR](state, error) {
        Object.assign(state, { error })
    },
    [types.WIDGET_LOADING](state) {
        Object.assign(state, { error: '', isLoading: !state.isLoading })
    },
    [types.SET_WIDGET](state, widget) {
        Object.assign(state, { widget } )
    },
    [types.SET_CHANNEL_ID](state, channelId) {
        Object.assign(state, { channelId })
    },
    [types.WIDGET_AUTH](state, widgetSet) {
        Object.assign(state, { widgetSet })
    },
    [types.WIDGET_SET_CURRENCY](state, baseCurrency) {
        Object.assign(state, { baseCurrency })
    }
}
