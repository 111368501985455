import {i18n} from "../i18n-setup";
export const CAR_TYPES = [
    {label: 'compact', name: () => i18n.t('car__Compact car'), helpText: () => i18n.t('car__e.g.', {example: 'VW Polo'}) },
    {label: 'medium', name: () => i18n.t('car__Medium car'), helpText: () => i18n.t('car__e.g.', {example: 'BMW 3'})  },
    {label: 'premium', name: () => i18n.t('car__Premium car'), helpText: () => i18n.t('car__e.g.', {example: 'BMW 7'})  },
    {label: 'suv', name: () => i18n.t('car__VAN/SUV'), helpText: () => i18n.t('car__e.g.', {example: 'MAZDA CX-9'})  }
]

export const MOTOR_BIKE_TYPES = [
    {label: 'up_to_125cc', name: () => i18n.t('motorBike__Up to 125 cc'), helpText: () => i18n.t('car__e.g.', {example: 'Vecpa Primavera 50'}) },
    {label: '125_to_500cc', name: () => i18n.t('motorBike__125 cc to 500 cc'), helpText: () => i18n.t('car__e.g.', {example: 'Kawasaki KLX250'})  },
    {label: 'above_500cc', name: () => i18n.t('motorBike__Above 500 cc'), helpText: () => i18n.t('car__e.g.', {example: 'Honda CB500'})  },
]
export const PUBLIC_TRANSPORT_TYPES = [
    {label: 'bus', name: () => i18n.t('publicTransport__Bus') },
    {label: 'coach', name: () => i18n.t('publicTransport__Coach') },
    {label: 'national_rail', name: () => i18n.t('publicTransport__National Rail') },
    {label: 'international_rail', name: () => i18n.t('publicTransport__International Rail') },
    {label: 'tram', name: () => i18n.t('publicTransport__Tram') },
    {label: 'subway', name: () => i18n.t('publicTransport__Tube / Subway') },
    {label: 'taxi', name: () => i18n.t('publicTransport__Taxi') },
]

export const FUEL_TYPES = [
    {label: 'petrol', name: () => i18n.t('car__Petrol'), unit: 'litre'},
    {label: 'diesel', name: () => i18n.t('car__Diesel'), unit: 'litre'},
    //{label: 'electro_eco', name: () => i18n.t('car__Electro eco'), unit: 'kWh'},
    {label: 'gas_cng', name: () => i18n.t('car__Gas natural'), unit: 'kg'},
    {label: 'gas_lpg', name: () => i18n.t('car__Gas lpg'), unit: 'litre'},
    {label: 'plugin_hybrid', name: () => i18n.t('car__Plugin hybrid'), unit: 'litre'}
]

export const
    NAMESPACE = 'car',
    DEFAULT_CONSUMPTION = [
        {fuel_type: 'petrol', car_type: 'compact', value: 4.5},
        {fuel_type: 'petrol', car_type: 'medium', value: 5.6},
        {fuel_type: 'petrol', car_type: 'premium', value: 9.5},
        {fuel_type: 'petrol', car_type: 'suv', value: 9.9},
        {fuel_type: 'diesel', car_type: 'compact', value: 3.9},
        {fuel_type: 'diesel', car_type: 'medium', value: 5.1},
        {fuel_type: 'diesel', car_type: 'premium', value: 8.4},
        {fuel_type: 'diesel', car_type: 'suv', value: 9.0},
        {fuel_type: 'gas_cng', car_type: 'compact', value: 3.75},
        {fuel_type: 'gas_cng', car_type: 'medium', value: 5},
        {fuel_type: 'gas_cng', car_type: 'premium', value: 7},
        {fuel_type: 'gas_cng', car_type: 'suv', value: 9},
        {fuel_type: 'gas_lpg', car_type: 'compact', value: 5.25},
        {fuel_type: 'gas_lpg', car_type: 'medium', value: 7},
        {fuel_type: 'gas_lpg', car_type: 'premium', value: 9.8},
        {fuel_type: 'gas_lpg', car_type: 'suv', value: 12.6},
        {fuel_type: 'plugin_hybrid', car_type: 'compact', value: 2.25},
        {fuel_type: 'plugin_hybrid', car_type: 'medium', value: 3},
        {fuel_type: 'plugin_hybrid', car_type: 'premium', value: 4.2},
        {fuel_type: 'plugin_hybrid', car_type: 'suv', value: 5.4}
    ];


/*
Elektrikli araçlar kaldırdım.
        {fuel_type: 'electro_eco', car_type: 'compact', value: 15},
        {fuel_type: 'electro_eco', car_type: 'medium', value: 20},
        {fuel_type: 'electro_eco', car_type: 'premium', value: 28},
        {fuel_type: 'electro_eco', car_type: 'suv', value: 36},
 */
