<template>
    <div>
        <Co2Loading v-if="isLoading"></Co2Loading>
        <div class="co2CalculationStart">
            <ValidationObserver v-slot="{ invalid }">
                <form @submit.prevent="onSubmit">

                    <div class="carCalculatorTreeColumn">
                        <div class="co2FormGroup">
                            <h4>{{$t('car__Type of vehicle')}}</h4>
                            <ValidationProvider name="vehicleType" rules="required" v-slot="{ errors, classes }">
                                <select class="co2FormControl co2selectBoxControl" v-model="vehicleType" :class="classes" >
                                    <option
                                            v-for="item in vehicle_types"
                                            :key="item.label"
                                            :value="item.label"
                                            :placeholder="$t('car__Type please select')">{{item.name()}}</option>
                                </select>
                                <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="distanceColumn">
                            <div class="co2FormGroup">
                                <h4>{{$t('module__transport__Distance km or miles')}}</h4>
                                <ValidationProvider
                                        name="distance"
                                        rules="required|numeric|is_not:0"
                                        v-slot="{ errors, classes }">
                                    <input type="number" step="10" min="50" v-model="distance" class="co2FormControl" :class="classes">
                                    <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="co2FormGroup">
                                <h4 class="fontWhite">{{$t('module__transport__Unit')}}</h4>
                                <div class="radiosContent">
                                    <div class="radios">
                                        <label>
                                            <input type="radio" id="km" value="km" v-model="distanceUnit" checked>
                                            <span class="text">Km</span>
                                        </label>
                                    </div>
                                    <div class="radios">
                                        <label>
                                            <input type="radio" id="mile" value="miles" v-model="distanceUnit">
                                            <span class="text">Mile</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="co2Grid-1">
                        <button type="submit" class="co2Button buttonCTA" :disabled="invalid">Calculate</button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
    import Co2Loading from "../Co2Loading";
    import { PUBLIC_TRANSPORT_TYPES } from "../../constant/vehicle_types";
    import { mapActions} from 'vuex';
    import { mapFields} from 'vuex-map-fields';

    export default {
        name: "PublicTransport",
        components: {Co2Loading},
        data() {
            return {
                vehicle_types: PUBLIC_TRANSPORT_TYPES,
                vehicleType: '',
                distance: 100,
                distanceUnit: 'km',
            }
        },
        computed: {
            ...mapFields('cart',[
                'error',
                'isLoading',
                'created',
                'violations',
                'isCompletedCart'
            ])
        },
        methods: {
            ...mapActions('cart',['addToCart', 'cartReset']),
            ...mapActions('projects', ['projectReset']),

            onSubmit() {
                let endpoint = 'calculator/public_transport';
                let payload = {
                    vehicleType: this.vehicleType,
                    distance: parseFloat(this.distance),
                    distanceUnit: this.distanceUnit,
                    cart: true
                };

                this.addToCart({endpoint, payload});
            }
        },
        watch: {
            created: function (created) {
                if (!created) {
                    return;
                }
                this.projectReset();
                this.$toasted.global.co2_success({
                    message: this.$t('Message__CartAdd', {quantity: created.data.emission })
                })
                this.$router.push({name: 'Cart'});
            },
            error: function (error) {
                if (!error) return;
                this.$toasted.global.co2_error({
                    message: error
                });

            }
        },
    }
</script>

<style scoped>

</style>
