var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('Co2Loading'):_vm._e(),_c('div',{staticClass:"co2CalculationStart"},[_c('div',{staticClass:"co2nsensusCalculationDescription"},[_vm._v(_vm._s(_vm.$t('Accommodation__Description__Apart')))]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"co2Grid-3"},[_c('div',{staticClass:"co2FormGroup"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Accommodation__Country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countries,"placeholder":_vm.$t('Accommodation__Country')},model:{value:(_vm.countryCode),callback:function ($$v) {_vm.countryCode=$$v},expression:"countryCode"}}),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"co2FormGroup"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Accommodation__Day'),"rules":"required|numeric|is_not:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.days),expression:"days"}],staticClass:"co2FormControl",class:classes,attrs:{"type":"number","placeholder":_vm.$t('Accommodation__Day')},domProps:{"value":(_vm.days)},on:{"input":function($event){if($event.target.composing){ return; }_vm.days=$event.target.value}}}),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"co2FormGroup"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Accommodation__numberOfPeople'),"rules":"required|numeric|is_not:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.numberOfPeople),expression:"numberOfPeople"}],staticClass:"co2FormControl",class:classes,attrs:{"type":"number","placeholder":_vm.$t('Accommodation__numberOfPeople')},domProps:{"value":(_vm.numberOfPeople)},on:{"input":function($event){if($event.target.composing){ return; }_vm.numberOfPeople=$event.target.value}}}),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"co2Grid-1"},[_c('button',{staticClass:"co2Button buttonCTA",attrs:{"type":"submit","disabled":invalid}},[_vm._v("Calculate")])])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }