import {i18n} from "../i18n-setup";
export const SHIPPING_TYPES = [
    {
        label: "ship",
        name: () => i18n.t('shipping__Sea transport'),
        icon: "co2icon-ship-cargo",
        vehicles: [
            {label: "containerShip", name: () => i18n.t('shipping__Container Ship')},
            {label: "roRoFerry", name: () => i18n.t('shipping__RoRo-Ferry')}
        ]
    },
    {
        label: "air",
        name: () => i18n.t('shipping__Air cargo'),
        icon: "co2icon-airplane-cargo",
        vehicles: [
            {label: "domestic", name: () => i18n.t('shipping__Domestic')},
            {label: "shortHaul", name: () => i18n.t('shipping__Short haul ( > 3700km )')},
            {label: "international", name: () => i18n.t('shipping__International & Long-haul (  < 3700km )')},
        ]
    },
    {
        label: "highway",
        name: () => i18n.t('shipping__Highway transport'),
        icon: "co2icon-higway-cargo",
        vehicles: [
            {label: "vans", name: () => i18n.t('shipping__Large goods vehicles (vans up to 3.5 tonnes)')},
            {label: "large", name: () => i18n.t('shipping__Large goods vehicles with maximum weight exceeding 3.5 tonnes.')},
            {label: "refrigerated", name: () => i18n.t('shipping__Refrigerated road vehicles with maximum weight exceeding 3.5 tonnes.')},
        ]
    }
]
