<template>
    <div>
        <Co2Loading v-if="isLoading"></Co2Loading>
        <section class="co2nsensusCalculationWrapper">
            <div class="co2CalculationBody">
                <div class="co2CalculationContent">
                    <h2 class="co2CalculationTitle">{{ $t('event__Event')}}</h2>
                    <div class="co2CalculationStart">
                        <div class="co2nsensusCalculationDescription">{{$t('event__title')}}</div>
                        <ValidationObserver v-slot="{ invalid }">
                            <form @submit.prevent="onSubmit">
                                <div class="co2Grid-1">
                                    <div class="co2FormGroup">
                                        <ValidationProvider name="name" rules="required" v-slot="{ errors, classes }">
                                            <input type="text" v-model="name" class="co2FormControl" :class="classes" :placeholder="$t('event__Event Name')">
                                            <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="co2Grid-3 ">

                                    <div class="co2FormGroup">
                                        <ValidationProvider name="participants" rules="required|integer|is_not:0" v-slot="{ errors, classes }">
                                            <input type="number" class="co2FormControl" v-model="participants" :class="classes" :placeholder="$t('event__Number of participants')">
                                            <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>

                                    <div class="co2FormGroup">
                                        <ValidationProvider name="overnightStays" rules="integer" v-slot="{ errors, classes }">
                                            <input type="number" class="co2FormControl" v-model="overnightStays" :class="classes" :placeholder="$t('event__Overnight Stays')">
                                            <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>

                                    <div class="co2FormGroup">
                                        <ValidationProvider name="meal" rules="integer|is_not:0" v-slot="{ errors, classes }">
                                            <input type="number" class="co2FormControl" v-model="meal" :class="classes" :placeholder="$t('event__Number of meals')">
                                            <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>


                                </div>
                                <div class="co2Grid-2 co2Gap20">

                                    <div class="co2FormGroup">
                                        <h4>{{$t('event__Origin of participants')}}</h4>
                                        <ValidationProvider name="origin" rules="required" v-slot="{ errors, classes }">
                                            <select class="co2FormControl co2selectBoxControl" v-model="origin" :class="classes">
                                                <option v-for="item in origins" :key="item.label" :value="item.label">{{ item.name() }}</option>
                                            </select>
                                            <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>

                                    <div class="co2FormGroup">
                                        <h4>{{$t('event__Food type')}}</h4>
                                        <ValidationProvider name="mealType" rules="" v-slot="{ errors, classes }">
                                            <select class="co2FormControl co2selectBoxControl" v-model="mealType" :class="classes">
                                                <option v-for="item in foodTypes" :key="item.label" :value="item.label">{{ item.name() }}</option>
                                            </select>
                                            <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="co2Grid-1 co2Gap20">
                                    <button type="submit" class="co2Button buttonCTA" :disabled="invalid">Calculate</button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {PARTICIPANTS_ORIGINS, FOOD_TYPES} from "../constant/event_types";
    import { mapActions } from 'vuex';
    import Co2Loading from "./Co2Loading";
    import { mapFields} from 'vuex-map-fields';

    export default {
        name: "Event",
        components: {Co2Loading},
        data() {
            return {
                name: '',
                participants: '',
                origin: '',
                origins: PARTICIPANTS_ORIGINS,
                meal: '',
                mealType: '',
                foodTypes: FOOD_TYPES,
                overnightStays: ''
            }
        },
        computed: {
            ...mapFields('cart',[
                'error',
                'isLoading',
                'created',
                'violations',
                'isCompletedCart'
            ])
        },
        methods: {
            ...mapActions('cart',['addToCart', 'cartReset']),
            ...mapActions('projects', ['projectReset']),
            onSubmit () {
                let endpoint = 'calculator/event';
                let payload = {
                    name: this.name,
                    participants: parseFloat(this.participants),
                    origin: this.origin,
                    meal: this.meal === '' ? 0 : parseFloat(this.meal),
                    overnightStays: this.overnightStays === '' ? 0 : parseFloat(this.overnightStays),
                    mealType: this.mealType,
                    cart: true
                };

                this.addToCart({endpoint, payload});
            },
        },
        watch: {
            created: function (created) {
                if (!created) {
                    return;
                }
                this.projectReset();
                this.$toasted.global.co2_success({
                    message: this.$t('Message__CartAdd', {quantity: created.data.emission })
                })
                this.$router.push({name: 'Cart'});
            },
            error: function (error) {
                if (!error) return;
                this.$toasted.global.co2_error({
                    message: error
                });

            }
        },
    }
</script>

<style scoped>

</style>
