import * as types from './mutation_types';

export default {
    [types.TOKEN_CREATE_ERROR](state, error) {
        Object.assign(state, { error });
    },

    [types.TOKEN_CREATE_LOADING](state) {
        Object.assign(state, {error: '', isLoading: !state.isLoading} )
    },

    [types.TOKEN_CREATE_SET_TOKEN](state, token ){
        Object.assign(state, { token })
    },

};
