var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('Co2Loading'):_vm._e(),_c('section',{staticClass:"co2nsensusCalculationWrapper"},[_c('div',{staticClass:"co2CalculationBody"},[_c('div',{staticClass:"co2CalculationContent"},[_c('h2',{staticClass:"co2CalculationTitle"},[_vm._v(_vm._s(_vm.$t('event__Event')))]),_c('div',{staticClass:"co2CalculationStart"},[_c('div',{staticClass:"co2nsensusCalculationDescription"},[_vm._v(_vm._s(_vm.$t('event__title')))]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"co2Grid-1"},[_c('div',{staticClass:"co2FormGroup"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"co2FormControl",class:classes,attrs:{"type":"text","placeholder":_vm.$t('event__Event Name')},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"co2Grid-3 "},[_c('div',{staticClass:"co2FormGroup"},[_c('ValidationProvider',{attrs:{"name":"participants","rules":"required|integer|is_not:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.participants),expression:"participants"}],staticClass:"co2FormControl",class:classes,attrs:{"type":"number","placeholder":_vm.$t('event__Number of participants')},domProps:{"value":(_vm.participants)},on:{"input":function($event){if($event.target.composing){ return; }_vm.participants=$event.target.value}}}),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"co2FormGroup"},[_c('ValidationProvider',{attrs:{"name":"overnightStays","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.overnightStays),expression:"overnightStays"}],staticClass:"co2FormControl",class:classes,attrs:{"type":"number","placeholder":_vm.$t('event__Overnight Stays')},domProps:{"value":(_vm.overnightStays)},on:{"input":function($event){if($event.target.composing){ return; }_vm.overnightStays=$event.target.value}}}),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"co2FormGroup"},[_c('ValidationProvider',{attrs:{"name":"meal","rules":"integer|is_not:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.meal),expression:"meal"}],staticClass:"co2FormControl",class:classes,attrs:{"type":"number","placeholder":_vm.$t('event__Number of meals')},domProps:{"value":(_vm.meal)},on:{"input":function($event){if($event.target.composing){ return; }_vm.meal=$event.target.value}}}),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"co2Grid-2 co2Gap20"},[_c('div',{staticClass:"co2FormGroup"},[_c('h4',[_vm._v(_vm._s(_vm.$t('event__Origin of participants')))]),_c('ValidationProvider',{attrs:{"name":"origin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.origin),expression:"origin"}],staticClass:"co2FormControl co2selectBoxControl",class:classes,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.origin=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.origins),function(item){return _c('option',{key:item.label,domProps:{"value":item.label}},[_vm._v(_vm._s(item.name()))])}),0),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"co2FormGroup"},[_c('h4',[_vm._v(_vm._s(_vm.$t('event__Food type')))]),_c('ValidationProvider',{attrs:{"name":"mealType","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.mealType),expression:"mealType"}],staticClass:"co2FormControl co2selectBoxControl",class:classes,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.mealType=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.foodTypes),function(item){return _c('option',{key:item.label,domProps:{"value":item.label}},[_vm._v(_vm._s(item.name()))])}),0),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"co2Grid-1 co2Gap20"},[_c('button',{staticClass:"co2Button buttonCTA",attrs:{"type":"submit","disabled":invalid}},[_vm._v("Calculate")])])])]}}])})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }