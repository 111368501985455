import axios from 'axios';
import {ENTRYPOINT} from "../../../../config/entrypoint";
import * as types from "./mutation_types";
import {i18n} from "../../../../i18n-setup";

const entryPoint = ENTRYPOINT + (ENTRYPOINT.endsWith('/') ? '' : '/');


export const createToken = async ({commit}, payload) => {
    commit(types.TOKEN_CREATE_ERROR, '');
    commit(types.TOKEN_CREATE_LOADING);

    return new Promise( (resolve, reject) => {
        axios({url: entryPoint + 'payments/stripe/token', data: payload, method: 'POST'})
            .then( (response) =>{
                commit(types.TOKEN_CREATE_SET_TOKEN, response.data.id);
                commit(types.TOKEN_CREATE_LOADING);
                resolve(response)
            })
            .catch(e => {
                commit(types.TOKEN_CREATE_LOADING);
                const error =
                    e.response.data['hydra:description'] ||
                    e.response.data['hydra:title'] ||
                    e.response.data['hydra:detail'] ||
                    i18n.t('Message__An error occurred.');

                console.log('Error', error);

                commit(types.TOKEN_CREATE_ERROR, error);
                reject(e)
            })
    })
}
