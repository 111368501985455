import * as types from './mutation_types';
import {IP_API_COUNTRY} from "../../../../config/entrypoint";

export const getCountryCode = ( {commit} ) => {
        fetch(IP_API_COUNTRY)
            .then(async response => {
                const data = await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response statusText
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }
                commit(types.SET_COUNTRY_CODE, data.country);

            })
            .catch(error => {
                this.errorMessage = error;
                console.error("There was an error!", error);
            });

}
