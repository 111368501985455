import * as types from './mutation_types';

export default {
    [types.CART_CREATE_SET_ERROR](state, error) {
        Object.assign(state, { error });
    },

    [types.CART_CREATE_TOGGLE_LOADING](state) {
        Object.assign(state, { error: '', isLoading: !state.isLoading });
    },

    [types.CART_CREATE_SET_CREATED](state, created) {
        Object.assign(state, { created });
    },

    [types.CART_CREATE_RESET](state) {
        Object.assign(state, {
            created: null,
            error: '',
            isLoading: false,
            violations: null,
        });
    },

    [types.CART_CLEAR](state) {
        localStorage.removeItem('co2nsensus-widget-cart');
        Object.assign(state, {
            created: null,
            error: '',
            isLoading: false,
            violations: null,
            localItems: JSON.parse('[]'),
        });
    },

    [types.ADD_TO_CART](state, product) {
        state.localItems.push(product);
    },

    [types.CART_CREATE_SET_COMPLETED](state) {
        Object.assign(state, {isCompletedCart: true})
    },

    [types.CART_DELETE_TOGGLE_LOADING](state) {
        Object.assign(state, { error: '', isLoading: !state.isLoading });
    },

    [types.CART_DELETE_ITEM](state, product) {
        if (state.localItems.length === 1 ) {
            localStorage.removeItem('co2nsensus-widget-cart');
            Object.assign(state, {
                localItems: [],
                deleted: true,
            });
        } else {
            const index = state.localItems.findIndex(localItems => localItems.id === product.product.id)
            state.localItems.splice(index, 1)
            Object.assign(state, {deleted: true})
        }

    },

    [types.CART_DELETED](state, deleted) {
      Object.assign(state, { deleted });
    },

    [types.CHANNEL_ID_CREATE](state, channelId) {
        Object.assign(state, { channelId })
    }

};

