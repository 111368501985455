export const CART_CREATE_RESET = 'CART_CREATE_RESET';
export const CART_CREATE_SET_CREATED = 'CART_CREATE_SET_CREATED';
export const CART_CREATE_SET_ERROR = 'CART_CREATE_SET_ERROR';
export const CART_CREATE_TOGGLE_LOADING = 'CART_CREATE_TOGGLE_LOADING';
export const ADD_TO_CART = 'ADD_TO_CART';
export const CART_CREATE_SET_COMPLETED = 'CART_CREATE_SET_COMPLETED';
export const CART_DELETE_TOGGLE_LOADING = 'CART_DELETE_TOGGLE_LOADING';
export const CART_DELETE_ITEM = 'CART_DELETE_ITEM';
export const CART_DELETED = 'CART_DELETED';
export const CART_CLEAR = 'CART_CLEAR';
export const CHANNEL_ID_CREATE = 'CHANNEL_ID_CREATE'
