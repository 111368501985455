import Vue from 'vue';
import i18next from "i18next";
import I18NextXhrBackend from "i18next-xhr-backend";
import {LOCALIZE_URL} from "./config/entrypoint";

import VueI18Next from "@panter/vue-i18next";

Vue.use(VueI18Next);

const lng = localStorage.getItem('co2-widget-language-code') || 'en';

i18next.use(I18NextXhrBackend);
i18next.init({
    backend: {
        loadPath: () => `${LOCALIZE_URL}static/locales/{{lng}}/{{ns}}.json`
    },
    lng: lng || 'tr',
    fallbackLng: ['en','tr'],
    whitelist: ['en','tr'],
    keySeparator: '__',
    nsSeparator: '::',
    ns: ['common','translation'],
    preload: ['en', 'tr'],
    returnEmptyString: false,
    parseMissingKeyHandler: (key) => `No translation found for "${key}"`
})

export const i18n = new VueI18Next(i18next);
