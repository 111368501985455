import * as types from "./mutation_types";

export default {
    [types.ORDER_CREATE_SET_ERROR](state, error) {
        Object.assign(state, { error });
    },

    [types.ORDER_CREATE_TOGGLE_LOADING](state) {
        Object.assign(state, { error: '', isLoading: !state.isLoading });
    },

    [types.ORDER_CREATED_SET_CREATED](state, created) {
        Object.assign(state, { created });
    },

    [types.ORDER_CLEAR](state) {
        localStorage.removeItem('co2nsensus-widget-order-info');
        localStorage.removeItem('co2nsensusLastOrderId');
        Object.assign(state, {
            created: null,
            error: '',
            isLoading: false,
            violations: null,
            orderInfo: null
        });
    },

    [types.ORDER_CREATE_SET_ORDER](state, order) {
        localStorage.setItem('co2nsensus-widget-order-info', JSON.stringify(order));
        Object.assign(state, {orderInfo: order})
    },

    [types.ORDER_CREATE_SET_COMPLETED](state) {
        Object.assign(state, {isCompletedOrder: true})
    },

    [types.ORDER_DELETE_TOGGLE_LOADING](state) {
        Object.assign(state, { error: '', isLoading: !state.isLoading });
    },

    [types.ORDER_CREATE_SET_ORDER_ID](state, orderId) {
        Object.assign(state, {orderId})
    }
}
