<template>
    <div>
        <section class="co2SalableProjects">
            <div class="projectSelectionCard"  v-for="(item, index) in projectItems" :key="index"
                    :class="item.projectId === selectedCartClass(selectedItem) ? 'projectSelected' : '' "
            >
                <div class="projectSelectionHeader" :style="item.image|bg_image"></div>
                <div class="projectSelectionHeaderPrice">
                    {{item.price|toCurrency}}
                </div>
                <div class="projectSelectionDetailText">
                    <h2>{{ item.name}}</h2>
                    <div class="projectSelectionSummary" v-html="`${item.description.substr(0,200)} ...`"></div>
                </div>
                <div class="projectSelectionButton">
                    <button class="co2Button buttonGradientBlue --full-widthButton" @click="selectProject(item)">Support now</button>
                </div>

            </div>
        </section>
    </div>
</template>

<script>
    import toCurrency from "../utils/to-currency";
    import {currencySymbols} from "../constant/currencies";
    import {mapActions, mapState} from 'vuex';
    import {mapFields} from 'vuex-map-fields';

    export default {
        name: "Projects",
        props: {
            projectItems: {
                type: Array,
                required: true
            }
        },
        data () {
            return {
                selectedProjectClass: 'projectSelected'
            }
        },
        computed: {
            ...mapFields('projects', [
                'selectedItem',
                'selected'
            ]),
            ...mapState('currency', ['currency'])
        },
        methods: {
            ...mapActions('projects', [
                'selectProject'
            ]),
            selectedCartClass(selectedItem) {
                if (!selectedItem) return;
                return selectedItem.projectId;
            }
        },
        watch: {
            selected: function (selected) {
                if (!selected) return;
                this.$router.push({name: 'Checkout'});
            }
        },
        filters: {
            bg_image (value) {
                return 'background-image: url('+encodeURI(value)+')';
            },
            toCurrency (value) {
                return currencySymbols[localStorage.getItem('co2Currency')] + '' + toCurrency(value)
            }
        }
    }
</script>

<style lang="less">
    @import "../assets/css/project.less";
</style>
