import axios from "axios"
import {ENTRYPOINT} from "@/config/entrypoint";
import * as types from './mutation_types'

const entryPoint = ENTRYPOINT + (ENTRYPOINT.endsWith('/') ? '' : '/');

export const createPaypalUrl = async ( {commit}, payload ) => {
    commit(types.PAYPAL_CREATE_ERROR, '');
    commit(types.PAYPAL_CREATE_TOGGLE_LOADING);

    return new Promise( (resolve, reject) => {
        axios({url: entryPoint + 'payments/paypal', data: payload, method: 'POST'})
            .then( (response) => {
                commit(types.PAYPAL_CREATE_PAYMENT_URL, response.data["paypalUrl"]);
                commit(types.PAYPAL_CREATE_TOGGLE_LOADING);
                resolve(response)
            })
            .catch( e => {
                commit(types.PAYPAL_CREATE_TOGGLE_LOADING);
                const error =
                    e.response.data['hydra:description'] ||
                    e.response.data['hydra:title'] ||
                    'An error occurred.';
                commit(types.PAYPAL_CREATE_ERROR, error);
                reject(e);
            })
    })
}
