import axios from "axios";
import {ENTRYPOINT} from "../../../config/entrypoint";
import * as types from "./mutation_types";

const endpoint = ENTRYPOINT + (ENTRYPOINT.endsWith('/') ? '' : '/');

axios.defaults.headers.common['co2ChannelId'] = localStorage.getItem('channelId');

export const getWidget = ( {commit}, widgetId ) => {
    commit(types.WIDGET_ERROR, '');
    commit(types.WIDGET_LOADING);

    return new Promise( (resolve, reject) => {
        axios({url: `${endpoint}widgets/${widgetId}`, method: 'GET' })
            .then( (response) =>{
                const channelId = response.data.channel.id;

                window.localStorage.setItem('channelId', channelId);
                window.localStorage.setItem('Co2Widget', JSON.stringify(response.data));
                localStorage.setItem('co2-widget-language-code', response.data['baseLanguage'])
                localStorage.setItem('apiKey', response.data.apiKey)

                axios.interceptors.request.use(
                    config => {
                        config.headers = {
                            'co2ChannelId' : channelId,
                            'X-AUTH-TOKEN': response.data.apiKey
                        }
                        return config;
                    },
                    error => Promise.reject(error)
                );

                commit(types.SET_WIDGET, response.data);
                commit(types.SET_CHANNEL_ID, channelId);
                commit(types.WIDGET_LOADING);
                commit(types.WIDGET_AUTH, true);
                commit(types.WIDGET_SET_CURRENCY, response.data.baseCurrency)
                resolve(response.data)
            })
            .catch( (e) => {
                commit(types.WIDGET_LOADING);
                const error =
                    e.response.data['hydra:description'] ||
                    e.response.data['hydra:title'] ||
                    e.response.data.detail ||
                    'An error occurred.';
                commit(types.WIDGET_ERROR, error);
                reject(e);
            })
    });

}

export const setChannelId = ( {commit}, channelId ) => {
    axios.defaults.headers.common['co2ChannelId'] = channelId;
    commit(types.SET_CHANNEL_ID, channelId);
}
