import axios from "axios"
import {ENTRYPOINT} from "../../../../config/entrypoint";
import * as types from './mutation_types'

const entryPoint = ENTRYPOINT + (ENTRYPOINT.endsWith('/') ? '' : '/');
const channelId = localStorage.getItem('channelId') ? localStorage.getItem('channelId') : '';
axios.defaults.headers['co2ChannelId'] = channelId;

export const getOrder = async ( {commit}, id ) => {
    commit(types.THANK_YOU_LOADING_TOGGLE);
    commit(types.THANK_YOU_ERROR, '');

    return new Promise( (resolve, reject) => {
        axios({url: entryPoint+'orders/'+id, method: 'GET'})
            .then( (response) => {
                commit(types.THANK_YOU_ORDER, response.data);
                commit(types.THANK_YOU_LOADING_TOGGLE);
                if (response.data.state === 'fulfilled') {
                    commit(types.THANK_YOU_STATUS, true );
                }
                resolve(response);
            })
            .catch( e => {
                commit(types.THANK_YOU_LOADING_TOGGLE);
                const error =
                    e.response.data['hydra:description'] ||
                    e.response.data['hydra:title'] ||
                    'An error occurred.';
                commit(types.THANK_YOU_ERROR, error);
                reject(e);
            })
    } )
}
