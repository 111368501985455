<template>
    <div>
        <Co2Loading v-if="isLoading"></Co2Loading>
        <section class="co2nsensusCalculationWrapper">
            <div class="co2CalculationBody">
                <div class="co2CalculationContent">
                    <div class="co2TabWrapper">
                        <div class="co2TabNavigationWrapper">
                            <div v-for="item in shipping_types" :key="item.label" class="co2TransportNav"
                                 :title="item.name()"
                                 @click="selectComponent(item.label)"
                                 :class="component === item.label ? 'active': '' ">
                                 <span>{{ item.name()}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="co2nsensusCalculationDescription">{{ $t('shipping__title') }}</div>

                    <div class="co2CalculationStart">
                        <ValidationObserver v-slot="{ invalid }">
                            <form @submit.prevent="onSubmit">
                                <div class="co2Grid-1 co2Gap20">
                                    <div class="co2FormGroup">
                                        <h4>Type</h4>
                                        <ValidationProvider name="vehicle" rules="required" v-slot="{ errors, classes }">
                                            <select v-model="vehicle" class="co2FormControl co2selectBoxControl" :class="classes">
                                                <option v-for="item in vehiclesTypes" :key="item.label" :value="item.label">
                                                    {{ item.name() }}
                                                </option>
                                            </select>
                                            <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="co2Grid-2 co2Gap20">
                                    <div class="co2FormGroup">
                                        <h4>{{ $t('shipping__Weight (Kg)')}}</h4>
                                        <ValidationProvider name="weight" rules="required|integer|is_not:0" v-slot="{ errors, classes }">
                                            <input type="number" v-model="weight" lang="en" step="10" min="10" class="co2FormControl" :class="classes">
                                            <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="co2FormGroup">
                                        <h4>{{$t('shipping__Distance travelled in', {unit: unit})}}</h4>
                                        <ValidationProvider
                                                name="distance"
                                                rules="required|numeric|is_not:0"
                                                v-slot="{ errors, classes }">
                                            <input type="number" step="1000" min="1000" v-model="distance" class="co2FormControl" :class="classes">
                                            <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="co2Grid-1">
                                    <button type="submit" class="co2Button buttonCTA" :disabled="invalid">Calculate</button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>

                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {SHIPPING_TYPES} from "../constant/shipping_types";
    import Co2Loading from "./Co2Loading";
    import { mapActions} from 'vuex';
    import { mapFields} from 'vuex-map-fields';
    export default {
        name: "Shipping",
        components: {Co2Loading},
        data() {
            return {
                shipping_types: SHIPPING_TYPES,
                component: 'ship',
                unit: 'km',
                weight: 0,
                distance: 1000,
                vehicle: '',
                vehiclesTypes: [],
            }
        },
        computed: {
            ...mapFields('cart',[
                'error',
                'isLoading',
                'created',
                'violations',
                'isCompletedCart'
            ])
        },
        created() {
            this.selectComponent('ship')
        },
        methods: {
            ...mapActions('cart',['addToCart', 'cartReset']),
            ...mapActions('projects', ['projectReset']),
            selectComponent(value) {
                const vehicles_types = SHIPPING_TYPES.find(x=> {
                    if (x.label === value) return true
                });

                this.component = value;
                this.vehiclesTypes = vehicles_types.vehicles;
            },

            onSubmit() {
                let endpoint = 'calculator/shipping';
                let payload = {
                    cargoType: this.component,
                    vehicle: this.vehicle,
                    distance: parseFloat(this.distance),
                    weight: parseFloat(this.weight),
                    cart: true
                };

                this.addToCart({endpoint, payload});
            }
        },

        watch: {
            created: function (created) {
                if (!created) {
                    return;
                }
                this.projectReset();
                this.$toasted.global.co2_success({
                    message: this.$t('Message__CartAdd', {quantity: created.data.emission })
                })
                this.$router.push({name: 'Cart'});
            },
            error: function (error) {
                if (!error) return;
                this.$toasted.global.co2_error({
                    message: error
                });

            }
        },
    }
</script>

<style scoped>

</style>
