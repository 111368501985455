<template>
    <div>
        <div class="cartSummaryWrapper" v-if="totalAmount > 0 && this.$route.name !== 'Cart' ">
            <div class="cartSummaryWrapperHeader">
                <div class="cartSummaryWrapperHeaderInfo">
                    <i class="co2nsensus-iconshopping-cart-icon-grey"></i>
                    <span>{{$t('common::Cart__Total Emission')}}: <span>{{totalAmount}} kgCO<sub>2</sub></span> </span>
                </div>
                <div class="cartSummaryWrapperHeaderAction" @click="showDetail()">
                    <span v-if="!detail">{{$t('common::Cart__Detail')}}</span>
                    <span v-else>{{$t('common::Cart__Hide')}}</span>
                    <i class="co2nsensus-iconctrl" :class="detail ? 'active' : '' "></i>
                </div>
            </div>
            <div class="cartSummaryWrapperDetail" v-if="detail">
                <div class="co2CartItem" v-for="item in localItems" :key="item.id">
                    <div class="co2CartItemEmission">
                        <div class="co2CartItemEmissionDetail">
                            <div class="productType">{{ item.productType }}</div>
                            <div>{{ item.productName }}</div>
                            <p v-if="item.productType === 'Flight Offset' ">
                                {{ item.productOptions.passenger }} passenger,
                                <span v-if="item.productOptions.direction === 1"> One Way</span>
                                <span v-else> Round Trip</span>,
                                {{ item.productOptions.cabinClass | capitalize}}
                            </p>
                        </div>
                        <div class="co2CartItemEmissionAmount">
                            {{ item.amount }} kgCo<sub>2</sub>
                        </div>
                    </div>
                </div>
                <div class="cartSummaryWrapperDetailTotal">
                    <span v-if="selectedItem">Total: <span>{{ selectedItem.price|toCurrency }}</span> </span>
                    <router-link :to="{name: 'Cart'}" v-if="selectedItem">
                        <button class="co2Button buttonTextOnlyWithArrow" @click="showDetail">{{$t('common::Buttons__View cart')}}</button>
                    </router-link>
                    <router-link :to="{name: 'Checkout'}" v-if="selectedItem  && this.$route.name !== 'Checkout' ">
                        <button class="co2Button buttonCTA " @click="showDetail">{{$t('common::Buttons__Checkout')}}</button>
                    </router-link>
                    <router-link :to="{name: 'Cart'}" v-if="!selectedItem">
                        <button class="co2Button buttonCTA " @click="showDetail">{{$t('common::Buttons__Choose carbon offset project')}}</button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters} from 'vuex';
    import { mapFields} from 'vuex-map-fields';
    import toCurrency from "../utils/to-currency";
    import {currencySymbols} from "../constant/currencies";
    export default {
        name: "CartSummary",
        data() {
            return{
                detail: false,
            }
        },
        computed: {
            ...mapFields('cart', [
                'localItems'
            ]),
            ...mapFields('projects', [
                'selectedItem'
            ]),
            ...mapGetters('cart', [
                'totalAmount'
            ]),
        },
        methods: {
            showDetail() {
                return this.detail = !this.detail;
            }
        },
        filters: {
            capitalize (value) {
                if (!value) return;
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            toCurrency (value) {
                return currencySymbols[localStorage.getItem('co2Currency')] + '' + toCurrency(value)
            }
        }
    }
</script>

<style scoped>

</style>
