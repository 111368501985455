import {i18n} from "../i18n-setup";

export const PARTICIPANTS_ORIGINS = [
    {label: "local", name: () => i18n.t('event__Local')},
    {label: "regional", name: () => i18n.t('event__Regional')},
    {label: "national", name: () => i18n.t('event__National')},
    {label: "europe", name: () => i18n.t('event__Europe')},
    {label: "international", name: () => i18n.t('event__International')},
]

export const FOOD_TYPES = [
    {label: "meat", name: () => i18n.t('event__Meal with meat')},
    {label: "vegetarian", name: () => i18n.t('event__Vegetarian meal')}
]
