<template>
    <div>
        <Co2Loading v-if="isLoading"/>
        <section class="co2CartWrapper">
            <div class="co2CartWrapper_Header">
                <h2>{{$t('common::Cart__Your Total Emission')}}: <span>{{ totalAmount | number}}</span> KgCo<sub>2</sub></h2>
            </div>
            <div class="co2CartContainerWrapper">
                <div class="co2CartProductsWrapper">

                    <div class="co2CartItem" v-for="item in cart.localItems" :key="item.id">
                        <div class="co2CartItemEmission">
                            <button
                                    class="emissionRemoveButton"
                                    type="button" :title="$t('common::Cart__Delete cart')"
                                    @click="removeCart(item)">
                                <svg width="10" height="10" version="1"><g fill="none" fill-rule="evenodd" stroke="#676F78" stroke-linecap="round"><path d="M1 1l8 8M1 9l8-8"></path></g></svg>
                            </button>
                            <div class="co2CartItemEmissionDetail">
                                <div class="productType">{{ item.productType }}</div>
                                <div>{{ item.productName }}</div>
                                <p v-if="item.productType === 'Flight Offset' ">
                                    {{ item.productOptions.passenger }} passenger,
                                    <span v-if="item.productOptions.direction === 1"> {{ $t('Plane__One way') }}</span>
                                    <span v-else> {{ $t('Plane__Round trip') }}</span>,
                                    {{ item.productOptions.cabinClass | capitalize}}
                                </p>
                            </div>
                            <div class="co2CartItemEmissionAmount">
                                {{ item.amount }} kgCo<sub>2</sub>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="co2CartActionsWrapper">
                    <div class="co2CartPriceBox" v-if="selectedItem">
                        <span>{{$t('common::Cart__Sub total')}}</span>
                        <span>{{ selectedItem.price | toCurrency}}</span>
                    </div>
                    <div class="co2Grid-1 co2Gap20">
                        <button class="co2Button buttonTextOnly buttonGreen buttonIcon" @click="addOneTonne">
                            <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><g id="icon-plus-icon-add" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path id="icon-plus-Path" fill="#000" fill-rule="nonzero" d="M42.1052632 0L42.1052632 42.1052632 0 42.1052632 0 57.8947368 42.1052632 57.8947368 42.1052632 100 57.8947368 100 57.8947368 57.8947368 100 57.8947368 100 42.1052632 57.8947368 42.1052632 57.8947368 0z"></path></g></svg>
                            {{$t('common::Buttons__Add one tonne')}}
                        </button>
                    </div>
                    <div class="co2Grid-1" v-if="selectedItem">
                        <router-link :to="{name: 'Checkout'}">
                            <button class="co2Button buttonCTA smallText --full-widthButton">{{$t('common::Buttons__Proceed to payment')}}</button>
                        </router-link>
                    </div>
                    <div class="co2Grid-1" v-else>
                        <button class="co2Button buttonTextOnlyWithArrow buttonSmall" v-scroll-to="'.co2SalableProjects'">{{$t('common::Buttons__Choose carbon offset project')}}</button>
                    </div>
                </div>
            </div>
        </section>
        <Projects v-if="cart.localItems.length>0" :project-items="items"/>
    </div>
</template>

<script>
    import {mapState, mapGetters, mapActions} from 'vuex'
    import {mapFields} from 'vuex-map-fields';
    import Co2Loading from "./Co2Loading";
    import Projects from "./Projects";
    import toCurrency from "../utils/to-currency";
    import {currencySymbols} from "../constant/currencies";

    export default {
        name: "Cart",
        components: {Projects, Co2Loading},
        computed: {
            ...mapFields('cart', [
                'error',
                'deleted',
                'isLoading',
                'created'
            ]),
            ...mapFields('projects',[
                'error',
                'isLoading',
                'items',
                'selected',
                'selectedItem',
                'selectedPrice'
            ]),
            ...mapState(['cart', 'projects']),
            ...mapGetters('cart',['totalAmount']),
            ...mapGetters('projects',['selectedProjectPrice']),
            ...mapState('currency', ['currency']),
        },
        methods: {
            ...mapActions('cart', [
                'removeFromCart',
                'addToCart',
                'cartReset'
            ]),
            ...mapActions('projects', [
                'getItems',
                'cartItemDeleted',
                'projectReset'
            ]),
            removeCart(item) {
              this.removeFromCart(item);
              this.cartItemDeleted();
            },
            getProjects() {
              if (this.$store.state.cart.localItems.length <= 0 ) return;

              const cartItems = this.$store.state.cart.localItems.map(function (x) {
                return x.id;
              })

              const payload = {
                cartItems: cartItems,
                currencyCode: this.$store.state.currency.currency,
                channelId: localStorage.getItem('channelId'),
              }

              this.getItems(payload);

            },

            addOneTonne() {
                let endpoint = 'calculator/direct';
                let payload = {
                    quantity: 1000,
                    cart: true
                };

                this.addToCart({endpoint, payload})
            }

        },
        created() {
            this.getProjects();
        },
        watch: {
            deleted: function (deleted) {
                if (!deleted) {
                    return;
                }
                this.getProjects();
            },
            created: function (created) {
                if (!created) {
                    return;
                }
                this.projectReset();
                this.getProjects();
                this.$toasted.global.co2_success({
                    message: this.$t('Message__CartAdd', {quantity: created.data.emission })
                })
            },
        },
        filters: {
            capitalize (value) {
                if (!value) return;
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            toCurrency (value) {
                if (value === null) return '';
                return currencySymbols[localStorage.getItem('co2Currency')] + '' + toCurrency(value)
            }
        }
    }
</script>

<style lang="less">
    @import "../assets/css/cart";
</style>
