import { getField } from "vuex-map-fields";
import * as actions from "./actions";
import mutations from "./mutations";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        error: '',
        widget: [],
        channelId: localStorage.getItem('channelId') || '',
        widgetSet: false,
        baseCurrency: ''
    },
    actions,
    getters: {
        getField,
    },
    mutations: {
        ...mutations
    }
}
