import * as types from "./mutation_types";

export default {
    [types.PAYPAL_CREATE_ERROR]( state, error ) {
        Object.assign(state, {error});
    },
    [types.PAYPAL_CREATE_TOGGLE_LOADING](state) {
        Object.assign(state, {error: '', isLoading: !state.isLoading})
    },
    [types.PAYPAL_CREATE_PAYMENT_URL](state, orderLink){
        Object.assign(state, {orderLink})
    }
}
