import axios from "axios"
import {ENTRYPOINT} from "../../../config/entrypoint";
import * as types from './mutation_types'

const entryPoint = ENTRYPOINT + (ENTRYPOINT.endsWith('/') ? '' : '/');


export const createOrder = async ( {commit}, payload ) => {
    commit(types.ORDER_CREATE_SET_ERROR, '');
    commit(types.ORDER_CREATE_TOGGLE_LOADING);

    return new Promise( (resolve, reject) => {
        axios({url: entryPoint+'orders', data: payload, method: 'POST'})
            .then( (response) => {
                commit(types.ORDER_CREATE_TOGGLE_LOADING);
                commit(types.ORDER_CREATED_SET_CREATED, response.data);
                commit(types.ORDER_CREATE_SET_ORDER, response.data);
                commit(types.ORDER_CREATE_SET_COMPLETED);
                commit(types.ORDER_CREATE_SET_ORDER_ID, response.data.id)
                localStorage.setItem('co2nsensusLastOrderId', response.data.id);
                resolve(response);
            } )
            .catch( e => {
                commit(types.ORDER_DELETE_TOGGLE_LOADING);
                const error =
                    e.response.data['hydra:description'] ||
                    e.response.data['hydra:title'] ||
                    'An error occurred.';
                commit(types.ORDER_CREATE_SET_ERROR, error);
                reject(e);
            })
    } )
}

export const updateOrder = async ({ commit }, payload) => {
    commit(types.ORDER_CREATE_SET_ERROR, '');
    commit(types.ORDER_CREATE_TOGGLE_LOADING);

    const availableOrderId = localStorage.getItem('co2nsensusLastOrderId')

    return new Promise( (resolve, reject) => {
        axios({url: `${entryPoint}orders/${availableOrderId}`, data: payload, method: 'PUT'})
            .then( (response) =>{
                commit(types.ORDER_CREATE_TOGGLE_LOADING);
                commit(types.ORDER_CREATED_SET_CREATED, response.data);
                commit(types.ORDER_CREATE_SET_ORDER, response.data);
                commit(types.ORDER_CREATE_SET_COMPLETED);
                resolve(response);
            })
            .catch(e=> {
                commit(types.ORDER_CREATE_TOGGLE_LOADING);
                const error =
                    e.response.data['hydra:description'] ||
                    e.response.data['hydra:title'] ||
                    'An error occurred.';
                commit(types.ORDER_CREATE_SET_ERROR, error);
                reject(e);
            })
    })

}

export const clearOrder = ( {commit} ) => {
    commit(types.ORDER_CLEAR)
}
