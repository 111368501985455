import axios from "axios"
import {ENTRYPOINT} from "../../../config/entrypoint";
import * as types from './mutation_types'

const entryPoint = ENTRYPOINT + (ENTRYPOINT.endsWith('/') ? '' : '/');


export const addToCart = async ( {commit}, {endpoint, payload})  => {
        commit(types.CART_CREATE_SET_ERROR, '');
        commit(types.CART_CREATE_TOGGLE_LOADING);
        return new Promise((resolve, reject) => {
            axios.post( entryPoint + endpoint, payload)
                .then( (response) => {
                    commit(types.CART_CREATE_TOGGLE_LOADING);
                    commit(types.CART_CREATE_SET_CREATED, response.data);
                    commit(types.ADD_TO_CART, response.data.cart)
                    commit(types.CART_CREATE_SET_COMPLETED)
                    resolve(response);
                })
                .catch( e => {
                    commit(types.CART_CREATE_TOGGLE_LOADING);
                    const error =
                        e.response.data['hydra:description'] ||
                        e.response.data['hydra:title'] ||
                        e.response.data.detail ||
                        'An error occurred.';
                    commit(types.CART_CREATE_SET_ERROR, error);
                    reject(e);
                })
        })
};

export const removeFromCart = async ( {commit} , product ) => {
    commit(types.CART_DELETE_TOGGLE_LOADING);
    commit(types.CART_DELETED, null);
    return new Promise( (resolve, reject) => {
        axios ({ url: entryPoint + 'carts/' + product.id, method: 'DELETE'})
            .then((response) => {
                commit(types.CART_DELETE_TOGGLE_LOADING);
                commit(types.CART_DELETE_ITEM, {product});
                resolve(response)
            })
            .catch( (e) => {
                commit(types.CART_DELETE_TOGGLE_LOADING);
                commit(types.CART_CREATE_SET_ERROR, e.response.data);
                reject(e);
            })
    })
};

export const cartReset = ({ commit }) => {
    commit(types.CART_CREATE_RESET);
};

export const clearCart = ({ commit }) => {
    commit(types.CART_CLEAR);
};
