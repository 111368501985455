<template>
    <div>
        <Co2Loading v-if="isLoading"></Co2Loading>
        <section class="co2nsensusCalculationWrapper">
            <div class="co2CalculationBody">
                <div class="co2CalculationContent">
                    <h2 class="co2CalculationTitle">{{ $t('building__title')}}</h2>
                    <div class="co2CalculationStart">
                        <ValidationObserver v-slot="{ invalid }">
                            <form @submit.prevent="onSubmit">
                                <div class="co2Grid-2 co2Gap20">
                                    <div class="co2FormGroup">
                                        <h4>{{ $t('building__Time Period Start') }}</h4>
                                        <ValidationProvider>
                                            <DatePicker v-model="timePeriodStart" valueType="format"></DatePicker>
                                        </ValidationProvider>
                                    </div>
                                    <div class="co2FormGroup">
                                        <h4>{{ $t('building__Time Period Finish') }}</h4>
                                        <ValidationProvider>
                                            <DatePicker v-model="timePeriodFinish" valueType="format"></DatePicker>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="co2Grid-2 co2Gap20">
                                    <div class="co2FormGroup">
                                        <h4>{{ $t('building__Country') }}</h4>
                                        <ValidationProvider name="country" rules="required" v-slot="{ errors }">
                                            <v-select :options="countries"
                                                      v-model="country"></v-select>
                                            <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="co2FormGroup">
                                        <h4>{{ $t('building__Total electricity consumption (kWh)') }}</h4>
                                        <ValidationProvider name="electricity" rules="required|integer|is_not:0" v-slot="{ errors, classes }">
                                            <input v-model="electricity" class="co2FormControl" :class="classes">
                                            <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="co2Grid-2 co2Gap20">
                                    <div class="co2FormGroup">
                                        <h4>{{ $t('building__Total natural gas consumption') }}</h4>
                                        <div class="co2FormInputGroups">
                                            <ValidationProvider name="gas" rules="integer|is_not:0" v-slot="{ errors, classes }">
                                                <input type="number" class="co2FormControl ilk" v-model="gas" :class="classes">
                                                <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                            <ValidationProvider>
                                                <select class="co2FormControl ikinci co2selectBoxControl" v-model="natural_gas_unit">
                                                    <option v-for="unit in natural_gas_units" :key="unit.label" :value="unit.label">{{unit.name()}}</option>
                                                </select>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="co2FormGroup">
                                        <h4>{{ $t('building__Total lpg consumption') }}</h4>
                                        <div class="co2FormInputGroups">
                                            <ValidationProvider name="lpg" rules="integer" v-slot="{ errors, classes }">
                                                <input type="number" class="co2FormControl ilk" v-model="lpg" :class="classes">
                                                <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                            <ValidationProvider>
                                                <select class="co2FormControl ikinci co2selectBoxControl" v-model="lpg_unit">
                                                    <option v-for="unit in lpg_units" :key="unit.label" :value="unit.label">{{unit.name()}}</option>
                                                </select>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div class="co2Grid-2 co2Gap20">
                                    <div class="co2FormGroup">
                                        <h4>{{ $t('building__Total heating oil consumption') }}</h4>
                                        <div class="co2FormInputGroups">
                                            <ValidationProvider name="heating_oil" rules="integer|is_not:0" v-slot="{ errors, classes }">
                                                <input type="number" class="co2FormControl ilk" v-model="heating_oil" :class="classes">
                                                <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                            <ValidationProvider>
                                                <select class="co2FormControl ikinci co2selectBoxControl" v-model="heating_oil_unit">
                                                    <option v-for="unit in heating_oil_units" :key="unit.label" :value="unit.label">{{unit.name()}}</option>
                                                </select>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="co2FormGroup">
                                        <h4>{{ $t('building__Total coal consumption') }}</h4>
                                        <div class="co2FormInputGroups">
                                            <ValidationProvider name="coal" rules="integer" v-slot="{ errors, classes }">
                                                <input type="number" class="co2FormControl ilk" v-model="coal" :class="classes">
                                                <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                            <ValidationProvider>
                                                <select class="co2FormControl ikinci co2selectBoxControl" v-model="coal_unit">
                                                    <option v-for="unit in coal_units" :key="unit.label" :value="unit.label">{{unit.name()}}</option>
                                                </select>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div class="co2Grid-1">
                                    <button type="submit" class="co2Button buttonCTA" :disabled="invalid">Calculate</button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import Co2Loading from "./Co2Loading";
    import { mapFields} from 'vuex-map-fields';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import { countryOptions} from "../constant/countries";
    import { NATURAL_GAS_UNIT,
        COAL_UNITS,
        HEATING_OIL_UNITS,
        LPG_UNITS
    } from "../constant/build_unit_types";

    export default {
        name: "Building",
        components: {Co2Loading, DatePicker},
        data() {
            return {
                timePeriodStart: '',
                timePeriodFinish: '',
                countries: countryOptions,
                country: this.selectedCityId(countryOptions, this.$store.state.zone.countryCode) || '',
                electricity: '',
                gas: '',
                coal: '',
                natural_gas_units: NATURAL_GAS_UNIT,
                natural_gas_unit: 'kWh',
                coal_units: COAL_UNITS,
                coal_unit: 'kWh',
                heating_oil: '',
                heating_oil_units: HEATING_OIL_UNITS,
                heating_oil_unit: 'kWh',
                lpg: '',
                lpg_units: LPG_UNITS,
                lpg_unit: 'kWh',
            }
        },
        computed: {
            ...mapFields('cart',[
                'error',
                'isLoading',
                'created',
                'violations',
                'isCompletedCart'
            ])
        },
        methods: {
            ...mapActions('cart',['addToCart', 'cartReset']),
            ...mapActions('projects', ['projectReset']),
            selectedCityId(data, id) {
                this.country = data.filter( function (item) {
                    return item.value == id
                } );

                return this.country;
            },
            onSubmit () {


                let endpoint = 'calculator/building';
                let payload = {
                    timePeriodStart: this.timePeriodStart,
                    timePeriodFinish: this.timePeriodFinish,
                    countryCode: this.country['value'] || this.country[0]['value'],
                    electricity: parseInt(this.electricity),
                    gas: parseInt(this.gas),
                    naturalGasUnit: this.natural_gas_unit,
                    coal: parseInt(this.coal),
                    coalUnit: this.coal_unit,
                    heatingOil: parseInt(this.heating_oil),
                    heatingOilUnit: this.heating_oil_unit,
                    lpg: parseInt(this.lpg),
                    lpgUnit: this.lpg_unit,
                    cart: true
                };

                this.addToCart({endpoint, payload});
            },
        },
        watch: {
            created: function (created) {
                if (!created) {
                    return;
                }
                this.projectReset();
                this.$toasted.global.co2_success({
                    message: this.$t('Message__CartAdd', {quantity: created.data.emission })
                })
                this.$router.push({name: 'Cart'});
            },
            error: function (error) {
                if (!error) return;
                this.$toasted.global.co2_error({
                    message: error
                });

            }
        },
    }
</script>

<style lang="less">
    .mx-datepicker {
        width: 100% !important;
        & .mx-input {
            box-sizing: border-box !important;
            padding: 15px 10px !important;
            border: 2px solid rgb(131, 141, 147) !important;
            border-radius: 4px !important;
            background: transparent !important;
            font-size: 1em !important;
            color: rgb(8, 10, 17) !important;
            font-weight: 400 !important;
            height: 46px !important;
            line-height: normal !important;
        }
    }


</style>
