import * as types from './mutation_types';

export default {
    [types.CHARGE_CREATE_ERROR](state, paymentError) {
        Object.assign(state, { paymentError });
    },

    [types.CHARGE_CREATE_LOADING](state) {
        Object.assign(state, {paymentError: '', isLoading: !state.isLoading} )
    },

    [types.CHARGE_CREATE_SET_CHARGE](state, charged ){
        Object.assign(state, { charged })
    },

};
