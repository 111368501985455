var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('Co2Loading'):_vm._e(),_c('section',{staticClass:"co2nsensusCalculationWrapper"},[_c('div',{staticClass:"co2CalculationBody"},[_c('div',{staticClass:"co2CalculationContent"},[_c('div',{staticClass:"co2TabWrapper"},[_c('div',{staticClass:"co2TabNavigationWrapper"},_vm._l((_vm.shipping_types),function(item){return _c('div',{key:item.label,staticClass:"co2TransportNav",class:_vm.component === item.label ? 'active': '',attrs:{"title":item.name()},on:{"click":function($event){return _vm.selectComponent(item.label)}}},[_c('span',[_vm._v(_vm._s(item.name()))])])}),0)]),_c('div',{staticClass:"co2nsensusCalculationDescription"},[_vm._v(_vm._s(_vm.$t('shipping__title')))]),_c('div',{staticClass:"co2CalculationStart"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"co2Grid-1 co2Gap20"},[_c('div',{staticClass:"co2FormGroup"},[_c('h4',[_vm._v("Type")]),_c('ValidationProvider',{attrs:{"name":"vehicle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.vehicle),expression:"vehicle"}],staticClass:"co2FormControl co2selectBoxControl",class:classes,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.vehicle=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.vehiclesTypes),function(item){return _c('option',{key:item.label,domProps:{"value":item.label}},[_vm._v(" "+_vm._s(item.name())+" ")])}),0),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"co2Grid-2 co2Gap20"},[_c('div',{staticClass:"co2FormGroup"},[_c('h4',[_vm._v(_vm._s(_vm.$t('shipping__Weight (Kg)')))]),_c('ValidationProvider',{attrs:{"name":"weight","rules":"required|integer|is_not:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.weight),expression:"weight"}],staticClass:"co2FormControl",class:classes,attrs:{"type":"number","lang":"en","step":"10","min":"10"},domProps:{"value":(_vm.weight)},on:{"input":function($event){if($event.target.composing){ return; }_vm.weight=$event.target.value}}}),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"co2FormGroup"},[_c('h4',[_vm._v(_vm._s(_vm.$t('shipping__Distance travelled in', {unit: _vm.unit})))]),_c('ValidationProvider',{attrs:{"name":"distance","rules":"required|numeric|is_not:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.distance),expression:"distance"}],staticClass:"co2FormControl",class:classes,attrs:{"type":"number","step":"1000","min":"1000"},domProps:{"value":(_vm.distance)},on:{"input":function($event){if($event.target.composing){ return; }_vm.distance=$event.target.value}}}),_c('span',{staticClass:"co2nsensus-form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"co2Grid-1"},[_c('button',{staticClass:"co2Button buttonCTA",attrs:{"type":"submit","disabled":invalid}},[_vm._v("Calculate")])])])]}}])})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }