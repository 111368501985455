import axios from 'axios';
import {ENTRYPOINT} from "../../../../config/entrypoint";
import * as types from "./mutation_types";

const entryPoint = ENTRYPOINT + (ENTRYPOINT.endsWith('/') ? '' : '/');


export const charge = async ({commit}, payload) => {
    commit(types.CHARGE_CREATE_ERROR, '');
    commit(types.CHARGE_CREATE_LOADING);

    return new Promise( (resolve, reject) => {
        axios({url: entryPoint + 'payments/stripe', data: payload, method: 'POST'})
            .then( (response) =>{
                commit(types.CHARGE_CREATE_SET_CHARGE, true);
                commit(types.CHARGE_CREATE_LOADING);
                resolve(response)
            })
            .catch(e => {
                commit(types.CHARGE_CREATE_LOADING);
                const error =
                    e.response.data['hydra:description'] ||
                    e.response.data['hydra:title'] ||
                    'An error occurred.';
                commit(types.CHARGE_CREATE_ERROR, error);

                reject(e)
            })
    })
}
