<template>
    <div>
        <Co2Loading v-if="isLoading"></Co2Loading>
        <div class="co2CalculationStart">
            <div class="co2nsensusCalculationDescription">{{$t('car__title')}}</div>
            <ValidationObserver v-slot="{ invalid }">
                <form @submit.prevent="onSubmit">
                    <div class="co2Grid-1">
                        <div class="co2FormGroup">
                            <h4>{{$t('car__Type of vehicle')}}</h4>
                            <ValidationProvider name="vehicleType" rules="required" v-slot="{ errors, classes }">
                                <select class="co2FormControl co2selectBoxControl" v-model="vehicleType" :class="classes" @change="getConsumption">
                                    <option
                                            v-for="item in vehicle_types"
                                            :key="item.label"
                                            :value="item.label"
                                            :placeholder="$t('car__Type please select')">{{item.name()}} {{item.helpText()}}</option>
                                </select>
                                <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="carCalculatorTreeColumn">
                        <div class="co2FormGroup">
                            <h4>{{$t('car__Fueltype')}}</h4>
                            <select class="co2FormControl co2selectBoxControl" v-model="fuelType" @change="getConsumption">
                                <option v-for="fuel in fuelTypes" :key="fuel.label" :value="fuel.label">{{fuel.name()}}</option>
                            </select>
                        </div>
                        <div class="distanceColumn">
                            <div class="co2FormGroup">
                                <h4>{{$t('module__transport__Distance km or miles')}}</h4>
                                <ValidationProvider
                                        name="distance"
                                        rules="required|numeric|is_not:0"
                                        v-slot="{ errors, classes }">
                                    <input type="number" step="1000" min="1000" v-model="distance" class="co2FormControl" :class="classes">
                                    <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="co2FormGroup">
                                <h4 class="fontWhite">{{$t('module__transport__Unit')}}</h4>
                                <div class="radiosContent">
                                    <div class="radios">
                                        <label>
                                            <input type="radio" id="km" value="km" v-model="distanceUnit" checked>
                                            <span class="text">Km</span>
                                        </label>
                                    </div>
                                    <div class="radios">
                                        <label>
                                            <input type="radio" id="mile" value="miles" v-model="distanceUnit">
                                            <span class="text">Mile</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="co2Grid-1 co2Gap20" v-if="!showAdditional">
                        <button v-if="!realConsumption" class="co2Button buttonTextOnly buttonGrey buttonIcon" @click="selectRealConsumption">
                            <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><g id="icon-plus-icon-add" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path id="icon-plus-Path" fill="#000" fill-rule="nonzero" d="M42.1052632 0L42.1052632 42.1052632 0 42.1052632 0 57.8947368 42.1052632 57.8947368 42.1052632 100 57.8947368 100 57.8947368 57.8947368 100 57.8947368 100 42.1052632 57.8947368 42.1052632 57.8947368 0z"></path></g></svg>
                            {{ $t('car__Enter customized consumption details')}}
                        </button>
                        <div class="co2FormGroup" v-else>
                            <h4>{{ consumptionLabel }}</h4>
                            <div class="co2FormInputGroups">
                                <ValidationProvider name="consumption" rules="required|is_not:0" v-slot="{ errors, classes}">
                                    <input type="number" lang="en" step="0.01" v-model="consumption" class="co2FormControl ilk" :class="classes">
                                    <span class="co2nsensus-form-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                                <button class="co2Button buttonGrey buttonSmall buttonIcon ikinci" @click="selectRealConsumption">
                                    <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><title>icon-delete</title><g id="icon-trash-icon-delete" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M66.7414547,13.2794213 L96.6477124,13.2794213 L96.6477124,20.4492042 L86.9498507,20.4492042 L80.8756022,100 L18.6038058,100 L12.5295572,20.4492042 L4,20.4492042 L4,13.2794213 L32.5089656,13.2794213 L32.5089656,-5.68434189e-14 L66.7414547,-5.68434189e-14 L66.7414547,13.2794213 Z M59.7316279,7.05063125 L39.6332862,7.05063125 L39.6332862,13.1600346 L59.7316279,13.1600346 L59.7316279,7.05063125 Z M25.0294804,92.7105953 L74.0982679,92.7105953 L79.707064,20.3241772 L19.539384,20.3241772 L25.0294804,92.7105953 Z M46.0577925,30.6715737 L53.186319,30.6715737 L53.186319,82.7281743 L46.0577925,82.7281743 L46.0577925,30.6715737 Z M66.2753012,82.9432114 L59.1605607,82.5530542 L61.9717349,30.4600266 L69.0864755,30.8501838 L66.2753012,82.9432114 Z M32.9610994,82.9432114 L30.1544816,30.8501838 L37.2692221,30.4646211 L40.0758399,82.5576487 L32.9610994,82.9432114 Z" id="icon-trash-Combined-Shape" fill="#000" fill-rule="nonzero"></path></g></svg>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="co2Grid-1">
                        <button type="submit" class="co2Button buttonCTA" :disabled="invalid">Calculate</button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
    import {CAR_TYPES, DEFAULT_CONSUMPTION, FUEL_TYPES} from "../../constant/vehicle_types";
    import { mapActions} from 'vuex';
    import { mapFields} from 'vuex-map-fields';
    import Co2Loading from "../Co2Loading";

    export default {
        name: "CarTransport",
        components: {Co2Loading},
        data() {
            return {
                vehicle_types: CAR_TYPES,
                vehicleType: 'medium',
                distance: 10000,
                distanceUnit: 'km',
                showAdditional: false,
                fuelType: 'petrol',
                fuelTypes: FUEL_TYPES,
                consumptionLabel: '',
                consumption: 1,
                realConsumption: false
            }
        },
        computed: {
            ...mapFields('cart',[
                'error',
                'isLoading',
                'created',
                'violations',
                'isCompletedCart'
            ])
        },
        created() {
          this.getConsumptionLabel();
          this.getConsumption();
        },
        methods: {
            ...mapActions('cart',['addToCart', 'cartReset']),
            ...mapActions('projects', ['projectReset']),

            selectRealConsumption() {
                this.realConsumption = !this.realConsumption;
            },

            getConsumptionLabel() {
                switch (FUEL_TYPES.find(({label}) => label === this.fuelType).unit) {
                    case 'kWh':
                        this.consumptionLabel = this.$t('car__Consumption kWh per 100 Kilometer');
                        break;
                    case 'kg':
                        this.consumptionLabel = this.$t('car__Consumption kg per 100 Kilometer');
                        break;
                    default:
                        this.consumptionLabel = this.$t('car__Consumption liter per 100 Kilometer');
                }
            },

            getConsumption () {

                const newConsumption = DEFAULT_CONSUMPTION.find((x) => {
                    if (x.fuel_type === this.fuelType && x.car_type === this.vehicleType) return true;
                });

                this.getConsumptionLabel();

                this.consumption = parseFloat(newConsumption.value);

            },

            onSubmit() {
                let endpoint = 'calculator/car-extended';

                const emission = !this.realConsumption ? null: parseFloat(parseFloat(this.consumption).toFixed(2))

                let payload = {
                    carType: this.vehicleType,
                    fuelType: this.fuelType,
                    consumption: emission,
                    distance: parseInt(this.distance),
                    distanceUnit: this.distanceUnit,
                    cart: true
                };

                this.addToCart({endpoint, payload});
            }


        },

        watch: {
            created: function (created) {
                if (!created) {
                    return;
                }
                this.projectReset();

                this.$toasted.global.co2_success({
                    message: this.$t('Message__CartAdd', {quantity: created.data.emission })
                })

                this.$router.push({name: 'Cart'});
            },
            error: function (error) {
                if (!error) return;
                this.$toasted.global.co2_error({
                    message: error
                });

            }
        },
        mounted() {
            this.vehicle_types.map(function (x){
                return x.item_data = x.name() + ' - ' + x.helpText()
            });
        },
    }
</script>

<style lang="less">
    @import "../../assets/css/form";
</style>
