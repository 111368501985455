import {i18n} from "../i18n-setup";


export const NATURAL_GAS_UNIT = [
    {label: 'kWh', name: () => 'kWh'},
    {label: 'therms', name: () => 'Therms'},
    {label: 'tonnes', name: () => i18n.t('building__Tonnes')},
    {label: 'cubic_metres', name: () => i18n.t('building__Cubic metres')}
];

export const COAL_UNITS = [
    {label: 'kWh', name: () => 'kWh'},
    {label: 'tonnes', name: () => i18n.t('building__Tonnes')},
    {label: 'litre', name: () => i18n.t('building__Litres')},
    {label: 'us_gallon', name: () => i18n.t('building__US Gallons')},
    {label: 'x10bag', name: () => i18n.t('building__x Kg Bags', {quantity: 10})},
    {label: 'x20bag', name: () => i18n.t('building__x Kg Bags', {quantity: 20})},
    {label: 'x25bag', name: () => i18n.t('building__x Kg Bags', {quantity: 25})},
    {label: 'x50bag', name: () => i18n.t('building__x Kg Bags', {quantity: 50})}
]

export const HEATING_OIL_UNITS = [
    {label: 'kWh', name: () => 'kWh'},
    {label: 'litre', name: () => i18n.t('building__Litres')},
    {label: 'tonnes', name: () => i18n.t('building__Tonnes')},
    {label: 'us_gallon', name: () => i18n.t('building__US Gallons')}
]

export const LPG_UNITS = [
    {label: 'kWh', name: () => 'kWh'},
    {label: 'litre', name: () => i18n.t('building__Litres')},
    {label: 'therms', name: () => 'Therms'},
    {label: 'tonnes', name: () => i18n.t('building__Tonnes')}
]

export const PROPANE_UNITS = [
    {label: 'litre', name: () => i18n.t('building__Litres')},
    {label: 'us_gallon', name: () => i18n.t('building__US Gallons')}
]
