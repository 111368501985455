import { getField, updateField } from "vuex-map-fields";
import * as actions from './actions';
import mutations from "./mutations";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        error: '',
        created: null,
        violations: null,
        orderInfo: JSON.parse(localStorage.getItem('co2nsensus-widget-order-info') ) || null,
        isCompletedOrder: false,
        deleted: null,
        orderId: null,
        lastOrderId: localStorage.getItem('co2nsensusLastOrderId') || null
    },
    actions,
    getters: {
        getField,
        getOrderId(state) {
            return state.orderId || state.lastOrderId
        }
    },
    mutations: {
        updateField,
        ...mutations
    }
}
