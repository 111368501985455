import * as types from './mutation_types';

export default {
    [types.RESET](state) {
        localStorage.removeItem('co2nsensus-selected-project');
        localStorage.removeItem('co2nsensus-selected-price');
        Object.assign(state, {
            error: '',
            items: [],
            selectItems: null,
            view: [],
            violations: null,
            selected: null,
            selectedItem: null,
        });
    },

    [types.RESET_SELECTED_PROJECT](state) {
        localStorage.removeItem('co2nsensus-selected-project');
        Object.assign(state, {
            selected: null,
            selectedItem: null,
        })
    },

    [types.SET_ERROR](state, error) {
        Object.assign(state, { error });
    },

    [types.SET_ITEMS](state, items) {
        Object.assign(state, {
            error: '',
            items,
        });
    },

    [types.SET_SELECT_ITEMS](state, selectItems) {
        Object.assign(state, { selectItems });
    },

    [types.TOGGLE_LOADING](state) {
        Object.assign(state, { isLoading: !state.isLoading });
    },

    [types.SET_VIEW](state, view) {
        Object.assign(state, { view });
    },

    [types.SET_SELECTED_PROJECT](state, selected) {
        Object.assign(state, { selected });
    },

    [types.SELECTED_PROJECT](state, selectedItem) {
        localStorage.setItem('co2nsensus-selected-project',
            JSON.stringify(selectedItem));
        Object.assign(state, { selectedItem });
    },

};
