<template>
  <div>
    <Co2Loading v-if="isLoading"></Co2Loading>
    <div class="co2CalculationStart">
      <div class="co2nsensusCalculationDescription">{{$t('Accommodation__Description__Apart')}}</div>
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="onSubmit">
          <div class="co2Grid-3">
            <div class="co2FormGroup">
              <ValidationProvider :name="$t('Accommodation__Country')" rules="required" v-slot="{errors}">
                <v-select
                    :options="countries"
                    v-model="countryCode" :placeholder="$t('Accommodation__Country')"></v-select>
                <span class="co2nsensus-form-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="co2FormGroup">
              <ValidationProvider :name="$t('Accommodation__Day')" rules="required|numeric|is_not:0" v-slot="{errors, classes }">
                <input type="number"  v-model="days"  class="co2FormControl" :class="classes"
                       :placeholder="$t('Accommodation__Day')">
                <span class="co2nsensus-form-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="co2FormGroup">
              <ValidationProvider :name="$t('Accommodation__numberOfPeople')" rules="required|numeric|is_not:0" v-slot="{errors, classes }">
                <input type="number"  v-model="numberOfPeople"  class="co2FormControl" :class="classes"
                       :placeholder="$t('Accommodation__numberOfPeople')">
                <span class="co2nsensus-form-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="co2Grid-1">
            <button type="submit" class="co2Button buttonCTA" :disabled="invalid">Calculate</button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import {countryOptions} from "@/constant/countries";
import {mapFields} from "vuex-map-fields";
import {mapActions} from "vuex";
import Co2Loading from "@/components/Co2Loading";

export default {
  name: "Apart",
  components: {Co2Loading},
  data() {
    return {
      countries: countryOptions,
      countryCode: '',
      days: '',
      numberOfPeople: '',
    }
  },
  computed: {
    ...mapFields('cart',[
      'error',
      'isLoading',
      'created',
      'violations',
      'isCompletedCart'
    ])
  },
  methods: {
    ...mapActions('cart',['addToCart', 'cartReset']),
    ...mapActions('projects', ['projectReset']),
    onSubmit() {
      let endpoint = 'calculator/apart';

      let payload = {
        countryCode: this.countryCode['value'] || this.countryCode[0]['value'],
        days: parseInt(this.days),
        numberOfPeople: parseInt(this.numberOfPeople),
        cart: true,
      };

      this.addToCart({ endpoint, payload });
    }
  },
  watch: {
    created: function (created) {
      if (!created) {
        return;
      }
      this.projectReset();

      this.$toasted.global.co2_success({
        message: this.$t('Message__CartAdd', {quantity: created.data.emission })
      })

      this.$router.push({name: 'Cart'});
    },
    error: function (error) {
      if (!error) return;
      this.$toasted.global.co2_error({
        message: error
      });

    }
  },
}
</script>

<style scoped>

</style>
