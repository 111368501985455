import { getField } from "vuex-map-fields";
import * as actions from './actions';
import mutations from "./mutations";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        error: '',
        created: null,
        token: null
    },
    actions,
    getters: {
        getField
    },
    mutations: {
        ...mutations
    }
}
