import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home'

Vue.use(VueRouter)

  const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/co2Cart',
      name: 'Cart',
      component: () => import('../components/Cart')
    },
    {
      path: '/co2Checkout',
      name: 'Checkout',
      component: () => import('../components/Checkout')
    },
    {
      path: '/co2TransporterCalculator',
      name: 'Transporter',
      component: () => import('../components/TransporterLayout')
    },
    {
      path: '/co2Accommodation',
      name: 'Accommodation',
      component: () => import('../components/Accommodation')
    },
    {
      path: '/co2ShippingCalculator',
      name: 'Shipping',
      component: () => import('../components/Shipping')
    },
    {
      path: '/co2EventsCalculator',
      name: 'Event',
      component: () => import('../components/Event')
    },
    {
      path: '/co2BuildingCalculator',
      name: 'Building',
      component: () => import('../components/Building')
    },
    {
      path: '/co2DirectOffset',
      name: 'Direct',
      component: () => import('../components/DirectOffset')
    },
    {
      path: '/co2Payment',
      name: 'Stripe',
      component: () => import('../components/Payment/Stripe')
    },
    {
      path: '/co2Paypal',
      name: 'Paypal',
      component: () => import('../components/Payment/Paypal')
    },
    {
      path: '/co2ThankYou/:id',
      name: 'ThankYou',
      component: () => import('../components/ThankYou')
    }
]

const router = new VueRouter({
  linkActiveClass: 'is-active',
  routes
})

export default router
