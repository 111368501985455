import {i18n} from "../i18n-setup";
export const HOTEL_ROOM_TYPES = [
    {label: "standard", name: () => i18n.t('Accommodation__RoomTypes__standard')},
    {label: "superior", name: () => i18n.t('Accommodation__RoomTypes__superior')},
    {label: "suit", name: () => i18n.t('Accommodation__RoomTypes__suit')}
]

export const RESORT_ROOM_TYPES = [
    {label: "room", name: () => i18n.t('Accommodation__RoomTypes__room')},
    {label: "villa", name: () => i18n.t('Accommodation__RoomTypes__villa')},
    {label: "luxury", name: () => i18n.t('Accommodation__RoomTypes__luxury')}
]
